import { Download } from "@mui/icons-material";
import {Button, Dialog, DialogContent, DialogTitle, Grid, Typography} from "@mui/material";
import { useEffect, useState } from "react";

import Search from "./components/Search";
import { create, update, exportAll, findEntityIsInUse, findSlaById, useList, getCenters, createCopy } from "../../../services/sla";
import { getLocal, getToken } from "../../../storage/auth";
import {useList as useListCenter} from "../../../services/centers";
import { SlaService } from "../../../types/sla";
import Form from "./components/Form";
import DataTable from "./components/DataTable";
import { hasClaim } from "../../../storage/userClaims";
import Copy from "../../../components/Copy";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import { InvoiceStatus } from "../../../types/invoice";
import useForms from "../../utils/forms/useForm.hook";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
}

const TransportationMode = [
  { id: "AIR", description: "AIR" },
  { id: "ROAD", description: "ROAD" },
  { id: "OCEAN", description: "OCEAN" },
]

const discrepancy = [
  { id: true, description: "All" },
  { id: false, description: "Specific" }
]

const TriageInvoiceStatus = [
  { id: InvoiceStatus.REGISTERED_INVOICE, description: InvoiceStatus.REGISTERED_INVOICE  },
  { id: InvoiceStatus.PENDING, description: InvoiceStatus.PENDING  },
  { id: InvoiceStatus.PENDING_SHIPMENT_AUTHORIZATION, description: InvoiceStatus.PENDING_SHIPMENT_AUTHORIZATION },
  { id: InvoiceStatus.WAITING_SISTER_INVOICE, description: InvoiceStatus.WAITING_SISTER_INVOICE  },
  { id: InvoiceStatus.SHIPMENT_AUTHORIZED, description: InvoiceStatus.SHIPMENT_AUTHORIZED  },
  { id: InvoiceStatus.INTERNATIONAL_TRANSIT, description: InvoiceStatus.INTERNATIONAL_TRANSIT  },
  { id: InvoiceStatus.CUSTOMS_CLEARANCE, description: InvoiceStatus.CUSTOMS_CLEARANCE  },
  { id: InvoiceStatus.DOMESTIC_TRANSIT, description: InvoiceStatus.DOMESTIC_TRANSIT  },
  { id: InvoiceStatus.CONCLUDED, description: InvoiceStatus.CONCLUDED  },
  { id: InvoiceStatus.FINALIZED, description: InvoiceStatus.FINALIZED  },
  { id: InvoiceStatus.CANCELED, description: InvoiceStatus.CANCELED  },
]

const IncotermsFreightType = [
  { id: "COLLECT", description: "COLLECT" },
  { id: "PREPAID", description: "PREPAID" }
]

const columns: GridColDef[] = [
  {
    field: "GC_NOME_CENTRO",
    headerName: "COMPANY/PLANT",
    headerClassName: "super-app-theme--header",
    flex: 1,
    renderCell: (params: GridRenderCellParams<any>) => {
      return `${params.row.GE_SIGLA_EMPRESA} - ${params.row.GC_DSC_SIGLA} - ${params.row.GC_NOME_CENTRO}`;
    },
  },
  {
    field: "ID_TIPO_INVOICE",
    headerClassName: "super-app-theme--header",
    headerName: "INVOICE TYPE",
    flex: 1,
  },
  {
    field: "ID_STATUS_INVOICE",
    headerClassName: "super-app-theme--header",
    headerName: "STATUS",
    flex: 1,
  },
  {
    field: "GD_DSC_DIVERGENCIA",
    headerClassName: "super-app-theme--header",
    headerName: "RULE",
    flex: 1,
  },
  {
    field: "MODAL",
    headerClassName: "super-app-theme--header",
    headerName: "MODAL",
    flex: 1,
  },
  {
    field: "GP_DSC_PRIORIDADE",
    headerClassName: "super-app-theme--header",
    headerName: "SERVICE LEVEL",
    flex: 1,
  },
  {
    field: "ID_FRETE_INCO",
    headerClassName: "super-app-theme--header",
    headerName: "FREIGHT INCOTERM",
    flex: 1,
  },
  {
    field: "SLA_PRINCIPAL",
    headerClassName: "super-app-theme--header",
    headerName: "MAIN SLA",
    flex: 1,
  },
  {
    field: "SLA_SECUNDARIO",
    headerClassName: "super-app-theme--header",
    headerName: "SLA SECONDARY",
    flex: 1,
  },
];

const mapData = (row, newCustomRegimes: { ID_CENTRO_DESTINATION: any }) => ({
  ID_TIPO_INVOICE: row.ID_TIPO_INVOICE,
  ID_STATUS_INVOICE: row.ID_STATUS_INVOICE,
  ID_MODAL: row.ID_MODAL,
  ID_FRETE_INCO: row.ID_FRETE_INCO,
  SLA_PRINCIPAL: row.SLA_PRINCIPAL,
  SLA_SECUNDARIO: row.SLA_SECUNDARIO,
  ID_PRIORIDADE: row.ID_PRIORIDADE,
  DSC_PENDENCIA: row.DSC_PENDENCIA,
  ID_DIVERGENCIA: row.ID_DIVERGENCIA,
  TODAS_DIVERGENCIAS: row.TODAS_DIVERGENCIAS,
  ID_CENTRO: newCustomRegimes.ID_CENTRO_DESTINATION,
});

export default function Sla() {
  const [token, setToken] = useState<string>();
  const [userValidation, setUserValidation] = useState<string>();
  const [entityInUse, setEntityInUse] = useState<boolean>(false);

  const [params, setParams] = useState<IParams>({ page: 1, limit: 10 } as IParams);
  const [isEditing, setIsEditing] = useState(false);

  const { combo: dataCenterCombo, comboSla } = useListCenter( params, token, true);
  const { data: dataCompanies } = useListCenter( params, token, false);

  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    const getUser = (getLocal("userInfo")) as any;
    try {
      setUserValidation(JSON.parse(getUser));
      setToken(getTokens);
    } catch (err) {}
  };

  useEffect(() => { (async () => getTokenSession())() }, []);

  const {
    data,
    meta,
    isLoading,

    center,
    invoiceType,
    autoDiscrepancy,
    serviceLevels,

    slaId,
    setSlaId,
  } = useList(params, token);
  const {
    selectedData,
    controlEdit,
    isSaving,
    modalOpen,
    setModalOpen,
    modalCopyOpen,
    setModalCopyOpen,
    errors,
    readOnly,
    paginationModel,
    setPaginationModel,

    handleRequest,
    handleEditData,
    handleFormSubmit,
    exportData,
    handleCancelEditData,
    onViewData,
    onNew,
    handleCancelCopy,
    handleCopy,
  } = useForms({
    actions: {
      create,
      exportAll,
      update,
      isInUse: findEntityIsInUse,
      createCopy
    },
    configs:{
      findKey: "ID_SLA",
      entityName: "SLA",
    },

    data,
    params,
    setParams
  });

  return (
    <Grid marginTop={4}>
      <Grid container spacing={1} direction="column">
        <Grid item xs={10}>
          <Typography variant="h5">SLA</Typography>
        </Grid>
        {!modalOpen ? (
          <Grid item xs={2} marginTop={1}>
            <Button
              sx={{
                display: hasClaim("SLA - Maintenance") ? "block" : "none",
              }}
              variant="contained"
              onClick={() => onNew()}
              data-testid="new-button"
            >
              New
            </Button>
          </Grid>
        ) : null}
      </Grid>
      {modalOpen ? (
        <Grid container alignItems="center">
          <Form
            onSubmit={handleFormSubmit}
            onCancel={handleCancelEditData}

            sla={selectedData}
            slaId={slaId}
            center={center}
            invoiceType={invoiceType}
            autoDiscrepancy={autoDiscrepancy}
            serviceLevels={serviceLevels}
            TransportationMode={TransportationMode}
            discrepancy={discrepancy}
            TriageInvoiceStatus={TriageInvoiceStatus}
            IncotermsFreightType={IncotermsFreightType}

            readOnly={readOnly}
            entityInUse={entityInUse}
            isLoading={isLoading}
            isEditing={isEditing}
            errors={errors}
          />
        </Grid>
      ) : null}

      <Search
        center={center}
        invoiceType={invoiceType}
        discrepancy={discrepancy}
        serviceLevels={serviceLevels}
        IncotermsFreightType={IncotermsFreightType}
        TriageInvoiceStatus={TriageInvoiceStatus}
        TransportationMode={TransportationMode}

        onSearch={handleRequest}
      />

      <Grid
          container
          marginTop={2}
          marginBottom={2}
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
      >
        <Grid item xs={3} justifyContent="center">
          <Button
              style={{ width: "100%", backgroundColor: "#d3d3d3", color: "#000" }}
              onClick={() => {
                setModalCopyOpen(true);
              }}
          >
            Copy
          </Button>
        </Grid>
        <Grid item>
          <Button
              variant="text"
              onClick={exportData}
              startIcon={<Download />}
          >
            Export XLSX
          </Button>
        </Grid>
      </Grid>

      <Grid>
        <DataTable
          loading={isLoading}
          data={data}
          onEdit={handleEditData}
          onView={onViewData}
          userValidation={userValidation}
          rowCount={meta?.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          } }
          pagination
          paginationModel={paginationModel}
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>

      <Dialog
          onClose={handleCancelEditData}
          open={modalCopyOpen}
          maxWidth="md"
          fullWidth={true}
      >
        <DialogTitle>Copy Company - Plant</DialogTitle>
        <DialogContent dividers={true}>
          <Copy
              dataCenterCombo={dataCenterCombo}
              handleCopy={(copyItems, newItems) => handleCopy(copyItems, newItems, mapData)}
              data={data}
              companies={dataCompanies}
              onCancel={handleCancelCopy}
              // isLoading={isSaving}
              errors={errors}

              idKey="slaid"
              columns={columns}
              getCenters={getCenters}
              comboRegime={comboSla}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
