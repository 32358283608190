import { GridColDef } from "@mui/x-data-grid";
import DataTableDefault from "../../../../../components/DataTable";
import DatatableModal, { ModalDataProps } from "./DatatableModal";
import { useState } from "react";
import { Button } from "@mui/material";
import { translate, getTableName } from "../../../../utils/translation";
import { useTranslation } from "../../hooks/translation.hook";
import { formatDate, isValidDate } from "../../../../utils/date";
import { isConvertibleToNumber } from "../../../../utils/strings";
import { useCustomReport } from "../../hooks/customReportData.hook";

interface DataTableProps {
  data: Array<any>;
  reportStructure: Array<any>;
  [x: string]: any;
}

export default function DataTable({
  data,
  dataRaw,
  reportStructure,
  ...props
}: DataTableProps) {
  const { translations } = useTranslation();
  const { relations } = useCustomReport();

  let columns: GridColDef[] = [];
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState<ModalDataProps>({
    title: '',
    data: [{}],
  });

  const openMoreInformation = (key: string, value: [{}]) => {
    setOpen(true);
    setModalData({
      title: key,
      data: value,
    });
  }

  const processData = (param: any) => {
    if(isValidDate(param)) {
      return formatDate(param);
    }
    if(isConvertibleToNumber(param)) {
      if(typeof param === 'number') {
        return param.toString().replace('.', ',');
      }
      return param.replace('.', ',');
    }
    return param;
  }

  const findOrder = (key: string, reportStructure: Array<any>) => {
    const keySplited = key.split('.');
    const fieldName = keySplited[keySplited.length - 1];

    let order = reportStructure.customReportFields
      .find((structureItem) => structureItem.NOME_ATRIBUTO === fieldName)?.ORDER

    if(!order) {
      order = reportStructure.customReportFields
      .find((structureItem) => structureItem.NOME_TABELA_ALIAS === fieldName)?.ORDER
    }

    return order;    
  }

  if(dataRaw?.length > 0) {
    const columnsSetted: Array<string> = [];
    for (const dataRow of dataRaw) {
      for (const [key] of Object.entries(dataRow)) {

        if(!columnsSetted.includes(key)) {
          const { tableName, keyToTranslate } = getTableName(key, relations);
          const headerText = `${translate(tableName, keyToTranslate, translations)}`
          const column = {
            field: key,
            headerName: headerText,
            headerClassName: "super-app-theme--header",
            flex: 1,
            minWidth: headerText.length * 10 + 15,
            minHeight: 60,
            order: findOrder(key, reportStructure),
            renderCell: (params) => {
              if(typeof params.value === 'string' || typeof params.value === 'number') {
                return processData(params.value);
              } else if((Array.isArray(params.value) && params.value.length === 0) || params.value === null || params.value === undefined) {
                return '-';
              } else if(typeof params.value === 'boolean') {
                return params.value ? 'yes' : 'no'
              } else {
                return <Button onClick={() => openMoreInformation(params.field, params.value)}>More Details</Button>;
              }
            },
          }
          columnsSetted.push(key);
          columns.push(column);
        }
      }
    }
  }
  
  columns.sort((a, b) => a.order - b.order);
  return (
    <>
      <DataTableDefault
        getRowHeight={() => 60}
        data-testid="report-data-table"
        data={data}
        columns={columns}
        autosizeOptions={{
          includeHeaders: false,
        }}
        pagination
        autoPageSize={false}
        pageSizeOptions={[10]}
        paginationMode="server"
        pageSize={10}
        {...props}
      />
      
      <DatatableModal
        open={open}
        modalData={modalData}
        reportStructure={reportStructure}
        callbackOnCancel={() => setOpen(false)}
      />
    </>
  );
}
