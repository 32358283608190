import { Download } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import Search from "../../../components/Search";
import {
  create,
  deleteResource,
  exportAll,
  update,
  useList,
  isInUse,
} from "../../../services/maintenanceEmail";

import { getLocal, getToken } from "../../../storage/auth";
import QuarantineForm from "./components/MaitenanceEmailForm";
import DataTable from "./components/DataTable";
import { hasClaim } from "../../../storage/userClaims";
import { useList as useCenter } from "../../../services/centers";
import { useList as useEmailTypes } from "../../../services/emailTypes";
import useForms from "../../utils/forms/useForm.hook";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
}

export default function MaintenanceEmailComponent() {
  const [token, setToken] = useState<string>();
  const [userValidation, setUserValidation] = useState<string>();

  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    const getUser = (getLocal("userInfo")) as any;
    setUserValidation(JSON.parse(getUser));
    setToken(getTokens);
  };
  useEffect(() => {
    getTokenSession();
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const { combo: centerCombo } = useCenter(params, token, true);
  const { combo: useEmailTypesCombo } = useEmailTypes(params, token, true);

  const { data, meta, isLoading } = useList(params, token);

  const {
    selectedData,
    controlEdit,
    isSaving,
    modalOpen,
    setModalOpen,
    openDeleteModal,
    setOpenDeleteModal,
    errors,
    paginationModel,
    readOnly,
    setPaginationModel,

    handleRequest,
    handleEditData,
    handleDeleteDataConfirmation,
    handleDeleteData,
    handleFormSubmit,
    exportData,
    handleCancelEditData,
    onViewData,
    onNew,
  } = useForms({
    actions: {
      create,
      deleteData: deleteResource,
      exportAll,
      update,
      isInUse
    },
    configs:{
      findKey: "ID_CENTRO_TIPO_EMAIL",
      entityName: "Maintenance E-mail",
    },

    data,
    params,
    setParams
  });

  return (
    <Grid marginTop={4}>
      <Grid container spacing={1} direction="column">
        <Grid item xs={10}>
          <Typography variant="h5">Maintenance E-mail</Typography>
        </Grid>
        {!modalOpen ? (
          <Grid item xs={2} marginTop={1}>
            <Button
              sx={{
                display: hasClaim("Email Type Center - Maintenance")
                  ? "block"
                  : "none",
              }}
              variant="contained"
              onClick={onNew}
            >
              New
            </Button>
          </Grid>
        ) : null}
      </Grid>
      {modalOpen ? (
        <Grid container alignItems="center">
          <QuarantineForm
            onSubmit={handleFormSubmit}
            quarantine={selectedData}
            onCancel={handleCancelEditData}
            isLoading={isSaving}
            errors={errors}
            controlEdit={controlEdit}
            centerCombo={centerCombo}
            useEmailTypesCombo={useEmailTypesCombo}
            readOnly={readOnly}
          />
        </Grid>
      ) : null}
      <Search
        centerPlant={centerCombo}
        useEmailTypesCombo={useEmailTypesCombo}
        isGeneral
        disableStatus
        onChange={handleRequest}
      />
      <Grid container marginTop={4} justifyContent="flex-end">
        <Button variant="text" onClick={exportData} startIcon={<Download />}>
          Export XLSX
        </Button>
      </Grid>
      <Grid>
        <DataTable
          loading={isLoading}
          data={data}
          onDelete={handleDeleteDataConfirmation}
          onEdit={handleEditData}
          onView={onViewData}
          userValidation={userValidation}
          rowCount={meta?.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          paginationModel={paginationModel}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete maintenance E-mail
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete this maintenance E-mail?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          <Button onClick={handleDeleteData}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
