import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { isAxiosError } from "axios";
import { useEffect, useState } from "react";

import { useList as useListCenter } from "../../../services/centers";
import { useList as useListConsolidationCenter } from "../../../services/consolidationCenter";
import {
  create,
  deleteShipment,
  exportAll,
  update,
  useList,
} from "../../../services/automaticAuthorizationSchedule";
import { getLocal, getToken } from "../../../storage/auth";
import CustomRegimeForm from "./components/Form";
import DataTable from "./components/DataTable";
import { hasClaim } from "../../../storage/userClaims";
import { AutomaticAuthorizationScheduleProps } from "../../../types/automaticAuthorizationSchedule";
import SearchAutomaticAuthorizationscheduler from "./components/Search";
import { useSnackbar } from "notistack";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
}

export default function LocationBin() {
  const { enqueueSnackbar } = useSnackbar();
  const [automaticAuthorizationSchedule, setautomaticAuthorizationSchedule] =
    useState<AutomaticAuthorizationScheduleProps>({
      AGEND_MONDAY: false,
      AGEND_TUESDAY: false,
      AGEND_WEDNESDAY: false,
      AGEND_THURSDAY: false,
      AGEND_FRIDAY: false,
      AGEND_SATURDAY: false,
      AGEND_SUNDAY: false,
    } as AutomaticAuthorizationScheduleProps);

  const [automaticScheduleId, setautomaticScheduleId] = useState<
    string | number
  >();
  const [isSaving, setIsSaving] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userValidation, setUserValidation] = useState<string>();
  const [token, setToken] = useState<string>();
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    const getUser = (getLocal("userInfo")) as any;
    setToken(getTokens);
    setUserValidation(JSON.parse(getUser));
  };

  useEffect(() => {
    getTokenSession();
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const [controlEdit, setControlEdit] = useState({
    loading: false,
    isInUse: false,
  });

  const [errors, setErrors] = useState<string[]>();
  const { data, meta, isLoading } = useList(params, token);
  const { combo: dataCenterCombo } = useListCenter(params, token, true);
  const { combo: dataConsolidationCenter } = useListConsolidationCenter(
    params,
    token,
    true
  );

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
  };

  const handleEditShipment = async (id: string | number) => {
    setErrors([])
    setReadOnly(false);
    const editCustomRegime = data.filter(
      (item: AutomaticAuthorizationScheduleProps) =>
        item.ID_AGENDA_AUTORIZACAO_EMBARQUE === id
    );
    if (editCustomRegime.length) {
      const data = editCustomRegime[0];
      setautomaticAuthorizationSchedule({
        ...data,
      });
      setModalOpen(true);
    }
    try {
      setControlEdit({
        loading: true,
        isInUse: true,
      });
      setControlEdit({
        loading: false,
        isInUse: true,
      });
    } catch (error) {
      enqueueSnackbar("It's not posible to edit, try again later", {
        variant: "error",
      });
      setControlEdit({
        loading: false,
        isInUse: false,
      });
    }
  };

  const handleDeleteCustomRegimeConfirmation = (id: string | number) => {
    setautomaticScheduleId(id);
    setOpenDeleteModal(true);
  };

  const handleDeleteCustomRegime = async () => {
    if (automaticScheduleId) {
      try {
        setIsSaving(true);
        await deleteShipment(automaticScheduleId);
        enqueueSnackbar(
          "Automatic Authorization Scheduler deleted successful",
          {
            variant: "success",
          }
        );
        setParams({ ...params });
      } catch (err: any) {
        if (err.response.status) {
          enqueueSnackbar("Automatic Authorization Scheduler is being used", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(err.response.data.message[0], { variant: "error" });
        }
      }
      setIsSaving(false);
      setOpenDeleteModal(false);
    }
  };

  const handleFormSubmit = async (
    values: AutomaticAuthorizationScheduleProps
  ) => {
    try {
      setIsSaving(true);
      if (values.ID_AGENDA_AUTORIZACAO_EMBARQUE) {
        const result = await update(
          values.ID_AGENDA_AUTORIZACAO_EMBARQUE,
          values
        );
        enqueueSnackbar(
          "Automatic Authorization Scheduler updated successful",
          {
            variant: "success",
          }
        );
        const paramsRequest = {
          ID_CENTRO: result.ID_CENTRO,
          ID_CENTRO_CONSOLIDADOR: result.ID_CENTRO_CONSOLIDADOR,
        };
        setParams((prevState) => ({ ...prevState, ...paramsRequest }));
      } else {
        const result = await create(values);
        enqueueSnackbar(
          "Automatic Authorization Scheduler created successful",
          {
            variant: "success",
          }
        );
        const paramsRequest = {
          ID_CENTRO: result.ID_CENTRO,
          ID_CENTRO_CONSOLIDADOR: result.ID_CENTRO_CONSOLIDADOR,
        };
        setParams((prevState) => ({ ...prevState, ...paramsRequest }));
      }
      setautomaticAuthorizationSchedule({
        AGEND_MONDAY: false,
        AGEND_TUESDAY: false,
        AGEND_WEDNESDAY: false,
        AGEND_THURSDAY: false,
        AGEND_FRIDAY: false,
        AGEND_SATURDAY: false,
        AGEND_SUNDAY: false,
      } as AutomaticAuthorizationScheduleProps);
      setModalOpen(false);
      setIsSaving(false);
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        setErrors([err.response?.data?.message]);
      }
      setIsSaving(false);
    }
    setIsSaving(false);
  };

  const exportAutomaticAuthorization = async () => {
    try {
      await exportAll(params);
    } catch (err) {}
  };

  const handleCancelEditCustomRegime = () => {
    setautomaticAuthorizationSchedule(
      {} as AutomaticAuthorizationScheduleProps
    );
    setErrors([]);
    setModalOpen(false);
    setControlEdit({
      loading: false,
      isInUse: false,
    });
  };
  const onViewCustomRegime = (id: string | number) => {
    setErrors([]);
    setReadOnly(true);

    const editCustomRegime = data.filter(
      (item: AutomaticAuthorizationScheduleProps) =>
        item.ID_AGENDA_AUTORIZACAO_EMBARQUE === id
    );
    if (editCustomRegime.length) {
      const data = editCustomRegime[0];
      setautomaticAuthorizationSchedule({
        ...data,
      });
    }
    setModalOpen(true);
  };

  return (
    <Grid marginTop={4}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <Typography variant="h5">
            Automatic Authorization Scheduler
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" marginTop={1}>
        <Grid item xs={2}>
          {!modalOpen ? (
            <Grid item xs={2}>
              <Button
                sx={{
                  display: hasClaim(
                    "Shipment Authorization - Scheduler - Maintenance"
                  )
                    ? "block"
                    : "none",
                }}
                variant="contained"
                onClick={() => {
                  setControlEdit({
                    loading: false,
                    isInUse: false,
                  });
                  setErrors([])
                  setReadOnly(false);
                  setModalOpen(true);
                }}
                fullWidth
              >
                New
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {modalOpen ? (
        <Grid container alignItems="center">
          <CustomRegimeForm
            consolidationCenterCombo={dataConsolidationCenter}
            centerWithRegime={dataCenterCombo}
            onSubmit={handleFormSubmit}
            automaticAuthorizationScheduler={automaticAuthorizationSchedule}
            onCancel={handleCancelEditCustomRegime}
            isLoading={isSaving}
            errors={errors}
            controlEdit={controlEdit}
            readOnly={readOnly}
          />
        </Grid>
      ) : null}
      <SearchAutomaticAuthorizationscheduler
        dataCenterCombo={dataCenterCombo}
        onChange={handleRequest}
        params={params}
        setParams={setParams}
        exportAutomaticAuthorization={exportAutomaticAuthorization}
      />
      <Grid marginTop={2} >
        <DataTable
          loading={isLoading}
          data={data}
          center={dataCenterCombo}
          userValidation={userValidation}
          onDelete={handleDeleteCustomRegimeConfirmation}
          onEdit={handleEditShipment}
          onView={onViewCustomRegime}
          rowCount={meta.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          paginationModel={paginationModel}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete Automatic Authorization Scheduler
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete this Automatic Authorization
            Scheduler?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          <Button onClick={handleDeleteCustomRegime}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
