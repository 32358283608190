import {
  AccordionDetails,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { Accordion } from "../../../components/Accordion/Accordion";
import { AccordionSummary } from "../../../components/Accordion/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Control, Controller, FieldErrors, UseFormRegister } from "react-hook-form";
import { Process } from "../../../types/process";
import { CustomChannelProps } from "../../../types/customChannel";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Invoice } from "../../../types/invoice";

type ProcessOthersProps = {
  process: Process,
  register: UseFormRegister<Process>,
  formErrors: FieldErrors<Process>,
  control: Control<Process>,
  customChannels: CustomChannelProps[],
  disabledByInvoiceAllFields: Invoice | undefined,
}

export default function ProcessOthers({ 
  register, 
  formErrors, 
  process, 
  control, 
  customChannels,
  disabledByInvoiceAllFields,
}: ProcessOthersProps) {
  return (
    <Accordion
      sx={{
        '&.MuiAccordion-root': {
          border: 0,
          marginBottom: 3,
          marginTop: 7
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography>Others</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Paper style={{ marginTop: 45, padding: 27, paddingBottom: 50 }}>
          <Grid container spacing={3}>
            <Grid item xs={12 / 4}>
              <TextField
                label="Customs Clearance Reference"
                fullWidth
                {...register("DSC_DESEMBARACO_DI")}
                error={!!formErrors.DSC_DESEMBARACO_DI}
                InputLabelProps={{ shrink: true }}
                disabled={!!disabledByInvoiceAllFields}
                size="small"
              />
            </Grid>
            <Grid item xs={12 / 4}>
              <Controller
                name="DATA_REG_DESEMBARACO_DI"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  const defaultDate = process?.DATA_REG_DESEMBARACO_DI ? dayjs(new Date(process.DATA_REG_DESEMBARACO_DI)) : null;
                  const currentValue = value ? dayjs(new Date(value)) : defaultDate;

                  return (
                    <MobileDatePicker
                      closeOnSelect
                      label="Register Date of Customs"
                      defaultValue={defaultDate}
                      value={currentValue}
                      disabled={!!disabledByInvoiceAllFields}
                      {...register("DATA_REG_DESEMBARACO_DI")} // Make sure register is correctly set up
                      ref={null}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                          error: !!formErrors.DATA_REG_DESEMBARACO_DI,
                          InputLabelProps: {
                            shrink: true
                          },
                        },
                      }}
                      format="MM/DD/YYYY"
                      onChange={(date: any) => {
                        const formattedDate = date && dayjs(date).isValid() ? dayjs(date).toISOString() : null;
                        onChange(formattedDate);
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12 / 4}>
              <Controller
                name="DATA_EXONERACAO_ICMS"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  const defaultDate = process?.DATA_EXONERACAO_ICMS ? dayjs(new Date(process.DATA_EXONERACAO_ICMS)) : null;
                  const currentValue = value ? dayjs(new Date(value)) : defaultDate;

                  return (
                    <MobileDatePicker
                      closeOnSelect
                      label="Taxes/Duties Payment Date"
                      defaultValue={defaultDate}
                      value={currentValue}
                      disabled={!!disabledByInvoiceAllFields}
                      {...register("DATA_EXONERACAO_ICMS")} // Make sure register is correctly set up
                      ref={null}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                          error: !!formErrors.DATA_EXONERACAO_ICMS,
                          InputLabelProps: {
                            shrink: true
                          },
                        },
                      }}
                      format="MM/DD/YYYY"
                      onChange={(date: any) => {
                        const formattedDate = date && dayjs(date).isValid() ? dayjs(date).toISOString() : null;
                        onChange(formattedDate);
                      }}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: 5 }}>
            <Grid item xs={12 / 4}>
              <Controller
                name="DATA_DESEMBARACO_DI"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  const defaultDate = process?.DATA_DESEMBARACO_DI ? dayjs(new Date(process.DATA_DESEMBARACO_DI)) : null;
                  const currentValue = value ? dayjs(new Date(value)) : defaultDate;

                  return (
                    <MobileDatePicker
                      closeOnSelect
                      label="Customers Clearence Date"
                      defaultValue={defaultDate}
                      value={currentValue}
                      disabled={!!disabledByInvoiceAllFields}
                      {...register("DATA_DESEMBARACO_DI")} // Make sure register is correctly set up
                      ref={null}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                          error: !!formErrors.DATA_DESEMBARACO_DI,
                          InputLabelProps: {
                            shrink: true
                          },
                        },
                      }}
                      format="MM/DD/YYYY"
                      onChange={(date: any) => {
                        const formattedDate = date && dayjs(date).isValid() ? dayjs(date).toISOString() : null;
                        onChange(formattedDate);
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12 / 4}>
              <Controller
                name="DATA_RECEBIMENTO_PORTARIA"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  const defaultDate = process?.DATA_RECEBIMENTO_PORTARIA ? dayjs(new Date(process.DATA_RECEBIMENTO_PORTARIA)) : null;
                  const currentValue = value ? dayjs(new Date(value)) : defaultDate;

                  return (
                    <MobileDatePicker
                      closeOnSelect
                      label="Receiving Date"
                      defaultValue={defaultDate}
                      value={currentValue}
                      {...register("DATA_RECEBIMENTO_PORTARIA")} // Make sure register is correctly set up
                      ref={null}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                          error: !!formErrors.DATA_RECEBIMENTO_PORTARIA,
                          InputLabelProps: {
                            shrink: true
                          },
                        },
                      }}
                      format="MM/DD/YYYY"
                      onChange={(date: any) => {
                        const formattedDate = date && dayjs(date).isValid() ? dayjs(date).toISOString() : null;
                        onChange(formattedDate);
                      }}
                      disabled
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12 / 4}>
              <Controller
                name="ID_CANAL"
                control={control}
                render={({ field: { value }, fieldState: { error } }) => {
                  return (
                    <TextField
                      select
                      {...register("ID_CANAL")}
                      value={value ?? null}
                      defaultValue={process?.ID_CANAL ?? null}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label="Customs Channel"
                      size="small"
                      error={!!formErrors.ID_CANAL}
                      type="tel"
                      disabled={!!disabledByInvoiceAllFields}
                    >
                      {customChannels?.map((customChannel) => (
                        <MenuItem key={customChannel.ID_CANAL} value={customChannel.ID_CANAL}>
                          {customChannel.DESCRICAO}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </AccordionDetails>
    </Accordion>
  )
}