import { useEffect, useState } from "react";
import { DataTableUtils } from "../../components/DataTable/DataTableUtils";
import { api } from "../../lib/api";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import { Label } from "../../types/po";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
  status?: boolean;
}

const entity = "labels";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  token?: string,
  isCombo?: boolean
):
  | Promise<{ data: Label[]; meta: List; isLoading: boolean }>
  | any => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Label[]>([{} as Label]);
  const [meta, setMeta] = useState<List>({} as List);
  const [combo, setCombo] = useState<any>();

  const render = () => {
    if (token) {
      if (isCombo === false || isCombo === undefined) {
        return "paginate";
      }
    }
  };

  useEffect(() => {
    if (render() === "paginate") {
      (async function () {
        setIsLoading(true);
        const response = await dataTableUtils.findPaginate(params);
        const {total, data, currentPage, totalPages, limit} = response.data;
        setData(data);
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }));
        setIsLoading(false)
      })();
    }
  }, [isCombo, params, token]);

  useEffect(() => {
    if (token && isCombo === true) {
      setIsLoading(true);
      api
        .get(`/${entity}/getCombo`, {
          params,
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const { data } = response;
          setCombo(data);
        })
        .finally(() => setIsLoading(false));
    }
  }, [isCombo, params, token]);

  return { data, meta, isLoading, combo };
};

export const getLabelInfo = async (poNumber: string, poLine: string) => {
  const token = getToken();
  const endpoint = `/${entity}/${poNumber}/${poLine}`;

  try {
    const { data } = await api.get(endpoint, {
      headers: { authorization: `Bearer ${token}` },
    });
    return data as any;
  } catch (error) {
    console.error(`Failed to fetch label info for PO ${poNumber} and Line ${poLine}:`, error);
    throw error; 
  }
};

export const getLabels = async (params?: any) => {
  const token = getToken();
  const endpoint = `/${entity}`;

  try {
    const { data } = await api.get(endpoint, {
      headers: { authorization: `Bearer ${token}` },
      params
    });
    return data as Label[];
  } catch (error) {
    console.error(`Failed to fetch labels info :`, error);
    throw error; 
  }
};

export const saveLabel = async (labelInfo: Label) => {
  const token = getToken();
  const endpoint = `/${entity}`;

  try {
    const { data } = await api.post(endpoint, labelInfo, {
      headers: { authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.error('Failed to save label:', error);
    throw error;
  }
};

export const editLabel = async (labelInfo: Label) => {
  const token = getToken();
  const endpoint = `/${entity}/${labelInfo?.ID_LABEL}`;

  try {
    const { data } = await api.put(endpoint, labelInfo, {
      headers: { authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.error('Failed to edit label:', error);
    throw error;
  }
};

export const deleteLabel = async (ID_LABEL: number) => {
  const token = getToken();
  const endpoint = `/${entity}/${ID_LABEL}`;

  try {
    const { data } = await api.delete(endpoint, {
      headers: { authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.error(`Failed to delete label for ID ${ID_LABEL}:`, error);
    throw error;
  }
};

export const exportAll = async (params: IList) => {
  let xlsxData = [
    { label: "Id", value: "label_ID_LABEL" },
    { 
      label: "PO Number", 
      value: "po_NUMERO_PO",
    },
    { 
      label: "PO line", 
      value: "poItem_NUM_LIN_PO",
    },
    {
      label: "Center",
      value: "poItem_SIGLA_CENTRO",
    },
    {
      label: "Incoterm",
      value: "po_SIGLA_INCOTERMS",
    },
    {
      label: "Incoterm Complement",
      value: "po_DSC_INCOTERMS_COMPL",
    },
    { label: "Tracking Number 1", value: "label_NUM_TRACKING_LABEL1" },
    { label: "Tracking Number 2", value: "label_NUM_TRACKING_LABEL2" },
  ]
  await dataTableUtils.exportAll({params, xlsxData, filename: "Labels"});
};