import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useList as useCenter } from "../../../services/centers";
import { getToken } from "../../../storage/auth";
import DataTableDefault from "../../../components/DataTable";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { Cached, Search } from "@mui/icons-material";
import { reprocess } from "../../../services/automaticAuthorizationRules";
import { fetchByCenter, getById } from "../../../services/boardingAuthorizationLog";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import LoadingOverlay from "../../../components/LoadingOverlay";

interface IParams {
  page: number;
  keyword: string;
  center: any;
  sort: string;
  limit: number;
  orderField: string;
}

type ResponseParams = {
  currentPage?: number;
  total?: number;
  totalPages?: number;
}

type ModalParams = IParams & ResponseParams & Record<'ID_REGRA_AUTORIZACAO_EMBARQUE', number>;

export default function AutomaticAuthorizationLog() {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [token, setToken] = useState<string>();
  const [params, setParams] = useState<IParams>({
    keyword: '',
    center: null,
    sort: 'DATA_ULTIMA_EXECUCAO',
    orderField: 'DESC',
    page: 1,
    limit: 100
  });
  const [automaticAuthorizations, setAutomaticAuthorizations] = useState({ data: [], currentPage: 1, limit: 100, total: 0 });
  const [boardingAuthorizations, setBoardingAuthorizations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const { enqueueSnackbar } = useSnackbar();

  const getTokenSession = async () => {
    const getTokens = (await getToken()) as any;
    setToken(getTokens);
  };

  const fetchAutomaticAuthorizationByCenter = async () => {
    try {
      setIsLoading(true);
      const { keyword, center, sort, orderField, page, limit } = params || {};
      if (!center?.id) return;

      const response = await fetchByCenter(center?.id, keyword, sort, orderField, page, limit);
      if (response?.data?.length <= 0) {
        enqueueSnackbar("Unable to find results", { variant: "error" });
      }
      setAutomaticAuthorizations(response);
      setTotal(response?.total);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to find results", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  }

  const debounceFetch = debounce(fetchAutomaticAuthorizationByCenter, 500);

  useEffect(() => {
    getTokenSession();
  }, []);

  useEffect(() => {
    debounceFetch();
  }, [params?.keyword, params]);

  const [base] = useState<IParams>({
    page: 1,
    limit: 100,
  } as IParams);

  const [modalParams, setModalParams] = useState<Partial<ModalParams>>({
    page: 1,
    limit: 5,
    currentPage: 1,
  });

  const { combo: dataCenterCombo } = useCenter(
    base,
    token,
    true
  );

  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
  };

  const onModal = async (ID_REGRA_AUTORIZACAO_EMBARQUE: number) => {
    setIsLoading(true);
    try {
      const response = await getById(ID_REGRA_AUTORIZACAO_EMBARQUE, modalParams.page!, modalParams.limit!);
      const { data, ...params } = response;
      setBoardingAuthorizations(data ?? []);
      setOpenDeleteModal(true);
      setModalParams((oldParams) => ({
        ...oldParams,
        ...params,
        ID_REGRA_AUTORIZACAO_EMBARQUE,
      }));
    } catch (error) {
      enqueueSnackbar('Could not find logs', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }

  const onPaginateBoardingAuthorizations = async (e: any) => {
    const { ID_REGRA_AUTORIZACAO_EMBARQUE } = modalParams;
    const currentPage = e.page + 1;
    const limit = e.pageSize;

    try {
      setIsLoading(true);
      const response = await getById(ID_REGRA_AUTORIZACAO_EMBARQUE!, currentPage, limit);
      const { data, ...params } = response;
      setBoardingAuthorizations(data);
      setModalParams((oldParams) => ({
        ...oldParams,
        ...params,
        ID_REGRA_AUTORIZACAO_EMBARQUE,
      }));
    } catch (error) {
      enqueueSnackbar('Could not find logs', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }

  const columns: GridColDef[] = [
    {
      field: "DESCRICAO_REGRA",
      headerName: "RULE",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "DATA_ULTIMA_EXECUCAO",
      headerName: "LAST UPDATE",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: any) => {
        return params?.row?.DATA_ULTIMA_EXECUCAO && dayjs(params?.row?.DATA_ULTIMA_EXECUCAO).format("MM/DD/YYYY HH:mm")
      }
    },
    {
      field: "note",
      type: "actions",
      headerName: "NOTES",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      getActions: ({ row }: any) => ([
        <GridActionsCellItem
          color="default"
          size="medium"
          style={{ background: '#1976d2' }}
          key={`notes-${row?.ID_REGRA_AUTORIZACAO_EMBARQUE}`}
          icon={<Search style={{ color: 'white' }} />}
          label="Notes"
          onClick={() => onModal(row?.ID_REGRA_AUTORIZACAO_EMBARQUE)}
        />
      ]),
    },
    {
      field: "retrocess",
      type: "actions",
      headerName: "REPROCESS",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      getActions: ({ row }: any) => ([
        <GridActionsCellItem
          color="default"
          size="medium"
          style={{ background: '#63c1fe' }}
          key={`retrocess-${row?.ID_REGRA_AUTORIZACAO_EMBARQUE}`}
          icon={<Cached style={{ color: 'white' }} />}
          label="Reprocess"
          onClick={async () => {
            await reprocess(row?.ID_REGRA_AUTORIZACAO_EMBARQUE);
            setTimeout(() => {
              fetchAutomaticAuthorizationByCenter();
            }, 10);
          }}
        />
      ]),
    },
  ];

  const infoColumns: GridColDef[] = [
    {
      field: "GTS_AUTORIZACAO_EMBARQUE_LOG_DATA_EXECUCAO",
      headerName: "DATE LOG",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: any) => {
        return params?.row?.GTS_AUTORIZACAO_EMBARQUE_LOG_DATA_EXECUCAO && dayjs(params?.row?.GTS_AUTORIZACAO_EMBARQUE_LOG_DATA_EXECUCAO).format("MM/DD/YYYY HH:mm")
      }
    },
    {
      field: "GTS_AUTORIZACAO_EMBARQUE_LOG_DSC_MENSAGEM",
      headerName: "NOTES",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
  ];

  return (
    <div id="centers">
      <LoadingOverlay isLoading={isLoading} />
      <Grid marginTop={4}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h5">Automatic Authorization Log</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center" marginTop={1}>
          <Grid item xs={12}>
            <Paper
              variant='outlined'
              sx={{ paddingTop: 2, paddingLeft: 5, paddingBottom: 2, paddingRight: 15 }}
            >
              <Grid container spacing={2} alignItems="center" marginTop={1} marginBottom={5}>
                <Grid item xs={5}>
                  <Autocomplete
                    options={dataCenterCombo || []}
                    getOptionLabel={(option: any) => option.description}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label="Center/Plant"
                        required
                        size="small"
                        variant="outlined"
                      />
                    )}
                    onChange={(_, data: any) => {
                      setParams((oldState: any) => ({ ...oldState, center: data }));
                    }}
                    value={params?.center || null}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    onClick={fetchAutomaticAuthorizationByCenter}
                    data-testid="submit-button"
                  >
                    <SearchIcon /> Search
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center" marginTop={1}>
          <Grid item xs={4}>
            <TextField
              value={params?.keyword}
              data-testid="description-input"
              fullWidth
              label="Type line for search*"
              variant="outlined"
              size="small"
              onChange={(value) => setParams((oldState: any) => ({ ...oldState, keyword: value.target.value }))}
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" marginTop={3}>
          <Grid item xs={12}>
            <DataTableDefault
              rowId={automaticAuthorizations?.data?.map((row: any) => row.ID_REGRA_AUTORIZACAO_EMBARQUE)}
              data-testid="custom-regime-data-table"
              data={automaticAuthorizations?.data}
              columns={columns}
              onPaginationModelChange={(e: any) => {
                const newPage = e.page + 1;
                const newLimit = e.pageSize;
                handleRequest({ page: newPage, limit: newLimit });
              }}
              pagination
              initialState={{
                pagination: { paginationModel: { pageSize: params.limit, page: params.page - 1 } },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              paginationMode="server"
              rowCount={total}
            />
          </Grid>
        </Grid>

        <Dialog
          scroll='paper'
          fullWidth={true}
          maxWidth='lg'
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Log Details
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <DataTableDefault
                rowId={boardingAuthorizations?.map((row: any) => row.ID_LOG_REGRA_AUTORIZACAO_EMBARQUE)}
                data-testid="custom-regime-data-table"
                data={boardingAuthorizations}
                columns={infoColumns}
                rowCount={modalParams.total}
                onPaginationModelChange={onPaginateBoardingAuthorizations}
                initialState={{
                  pagination: { paginationModel: { pageSize: modalParams.limit, page: modalParams.currentPage! - 1 } },
                }}
                paginationMode="server"
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteModal(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
}
