import { Button, DialogActions, Grid, Typography, Dialog, DialogContent, DialogContentText } from "@mui/material";
import { isAxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState, Fragment } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import { create, useList, update, getInvoice } from "../../../services/invoice";
import { getToken } from "../../../storage/auth";
import Form from "./components/Form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate, useParams } from "react-router-dom";
import { InvoiceAttachment, InvoiceFormModel } from "../../../types/invoice";
import LoadingOverlay from "../../../components/LoadingOverlay";

export default function Invoice() {
  const navigate = useNavigate();
  const { id: idInvoice } = useParams<{ id: string }>();

  const [token, setToken] = useState<string>();
  const [readOnly] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [msg, setMsg] = useState<{ DSC_OBSERVACAO: string }[]>([]);
  const [invoiceForm, setInvoiceForm] = useState<InvoiceFormModel>({} as InvoiceFormModel);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getTokenSession = () => {
    const getTokens = getToken();
    setToken(getTokens);
  };

  useEffect(() => {
    getTokenSession();
  }, []);

  useEffect(() => {
    const preventDefault = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    document.addEventListener('dragover', preventDefault);
    document.addEventListener('drop', preventDefault);

    return () => {
      document.removeEventListener('dragover', preventDefault);
      document.removeEventListener('drop', preventDefault);
    };
  }, []);

  useEffect(() => {
    handleGetInvoiceById();
  }, [idInvoice]);

  const handleGetInvoiceById = async (): Promise<void> => {
    setIsLoading(true);
    if (idInvoice) {
      const invoice = await getInvoice(+idInvoice);

      setInvoiceForm({
        ...invoice,
        VALOR_TOTAL: (+invoice.VALOR_TOTAL).toString(),
        VALOR_TAXAS_EXTRAS: invoice?.VALOR_TAXAS_EXTRAS ? (+invoice?.VALOR_TAXAS_EXTRAS).toString() : ''
      });
    }
    setIsLoading(false);
  }

  const [errors, setErrors] = useState<string[]>();
  const {
    invoiceAttachmentTypes,
    invoiceTypes,
    currencies,
    incoterms,
    incotermsComplements,
    serviceLevel,
    domesticCarrier,
    countries,
    transportationMode,
  } = useList(token);

  const handleFormatInvoiceData = (invoice: InvoiceFormModel): FormData => {
    const formData = new FormData();
    const fields = [
      'NUM_PESO_BRUTO',
      'ID_PARCEIRO_BROKER',
      'ID_PARCEIRO_DOMESTIC_CARRIER',
      'ID_REGIME',
      'ID_TIPO_INVOICE',
      'NUM_TRACKING',
      'ID_PRIORIDADE',
      'ID_INCOTERMS_COMPL',
      'DSC_INCOTERMS_COMPL',
      'ID_INCOTERMS',
      'VALOR_TAXAS_EXTRAS',
      'VALOR_TOTAL',
      'ID_MOEDA',
      'DATA_RECEBIMENTO',
      'DATA_INVOICE',
      'NUM_INVOICE',
      'ID_PO',
      'ID_PAIS_ORIGEM',
      'ID_MODAL',
      'ID_PARCEIRO_AGENTE_CARGA',
      'ID_INVOICE'
    ];

    fields.forEach((field: string) => {
      const value = invoice[field as keyof InvoiceFormModel];

      if (!value) {
        return;
      }

      formData.append(field, value.toString());
    });

    invoice?.invoiceAttachments?.forEach((invoiceAttachment: InvoiceAttachment, index: number) => {
      if (invoiceAttachment.FILE) {
        formData.append("files", invoiceAttachment.FILE);
        invoiceAttachment.ID_INVOICE_ANEXO && formData.append(`invoiceAttachmentsTypes[${index}][ID_INVOICE_ANEXO]`, invoiceAttachment?.ID_INVOICE_ANEXO.toString());
        formData.append(`invoiceAttachmentsTypes[${index}][FILE_NAME]`, invoiceAttachment?.FILE.name);
        formData.append(`invoiceAttachmentsTypes[${index}][ID_TIPO_ANEXO]`, invoiceAttachment.ID_TIPO_ANEXO);
      }
    });

    (invoice?.invoiceAttachmentsToDelete as InvoiceAttachment[] | undefined)?.forEach((invoiceAttachment, index: number) => {
      formData.append(`invoiceAttachmentsToDelete[${index}][ID_INVOICE_ANEXO]`, invoiceAttachment?.ID_INVOICE_ANEXO?.toString() ?? '');
      formData.append(`invoiceAttachmentsToDelete[${index}][ID_TIPO_ANEXO]`, invoiceAttachment.ID_TIPO_ANEXO);
      formData.append(`invoiceAttachmentsToDelete[${index}][PATH]`, invoiceAttachment?.DSC_PATH ?? '');
    });

    return formData;
  }

  const handleFormSubmit = async (newInvoice: InvoiceFormModel): Promise<void> => {
    setIsLoading(true);
    setInvoiceForm(newInvoice);

    try {
      if (idInvoice) {
        newInvoice.ID_INVOICE = +idInvoice;
      }
 
      const invoiceFormated = handleFormatInvoiceData(newInvoice);
      const result = await handleAction(invoiceFormated, false);
      if (result.hasDiscrepancy) {
        setOpen(true);
        setMsg(result.hasDiscrepancy);

        return;
      }

      setErrors([]);
      setMsg([]);

      navigate(`/invoice/details/${result.ID_INVOICE}`);
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        setErrors(err.response?.data?.message);
        enqueueSnackbar(`${err.response?.data?.message[0]}`, { variant: "error" });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAction = async (data: FormData, save: boolean): Promise<InvoiceFormModel> => {
    if (idInvoice) {
      return await update(data, save);
    }

    return await create(data, save);
  }

  const handleClose = (): void => {
    setMsg([]);
    setOpen(false);
  };

  const navigateToList = (): void => {
    navigate(-1);
  }

  const handleConfirm = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const invoiceFormated = handleFormatInvoiceData(invoiceForm);

      const response = await handleAction(invoiceFormated, true);
      setOpen(false);
      enqueueSnackbar("Invoice successfully created", { variant: "success" });
      setErrors([]);
      setMsg([]);
      navigate(`/invoice/details/${response.ID_INVOICE}`);
    } catch (err: unknown) {
      setOpen(false);
      if (isAxiosError(err)) {
        setErrors(err.response?.data?.message ?? 'Error fetching data');
        enqueueSnackbar("Erro to created invoice", { variant: "error" });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const clearErrors = () => {
    setErrors([]);
    setMsg([]);
  }

  return (
    <Grid marginTop={4}>
      <LoadingOverlay isLoading={isLoading} />
      <Grid container spacing={1} direction="column" marginBottom={4}>
        <Grid item xs={10}>
          <Typography variant="h5">Invoice</Typography>
        </Grid>
      </Grid>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Form
          invoice={invoiceForm}
          setInvoice={setInvoiceForm}
          invoiceAttachmentTypes={invoiceAttachmentTypes}
          invoiceTypes={invoiceTypes}
          currencies={currencies}
          incoterms={incoterms}
          incotermsComplements={incotermsComplements}
          serviceLevel={serviceLevel}
          domesticCarrier={domesticCarrier}
          countries={countries}
          transportationMode={transportationMode}
          readOnly={readOnly}
          onSubmit={handleFormSubmit}
          isLoading={isLoading}
          onReturn={navigateToList}
          errors={errors}
          onUpdate={handleGetInvoiceById}
          onClearErrors={clearErrors}
        />
      </LocalizationProvider>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>You have discrepancies</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm if you want add invoice with discrepancies:
            {msg.map((item) => (
              <Fragment key={item.DSC_OBSERVACAO}>
                <br></br>
                <strong>- {item.DSC_OBSERVACAO}</strong>
              </Fragment>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
