import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { deleteCustomReport, findCustomReportStructure, saveCustomReport } from "../../../../services/report";
import CustomReportTable from "./customReportTable";
import { useSnackbar } from "notistack";
import { isAxiosError } from "axios";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { ComboBoxType } from "../../../../types/general";
import { useCustomReport } from "../hooks/customReportData.hook";

interface CustomReportProps {
  ID_RELATORIO: number,
  setCustomReport: (value: boolean) => void,
  reportSelected: ComboBoxType,
  setReportSelected: (comboSelected: ComboBoxType) => void,
  customReportCombo: ComboBoxType[],
  setCustomReportCombo: (combo: ComboBoxType[]) => void,
}

export default function CustomReport({
  ID_RELATORIO,
  setCustomReport,
  reportSelected,
  setReportSelected,
  customReportCombo,
}: CustomReportProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [errors, setErrors] = useState<string[]>();
  const [ nomeCustom, setNomeCustom ] = useState<string>();

  const { watch, reset, handleSubmit, entities, register, formErrors, isLoading } = useCustomReport();

  const customReportId = watch('ID_RELATORIO_CUSTOM');

  const onSubmit = async (data) => {
    setErrors([]);
    try {
      if(isNewRegister()) {
        data.FILTRO_PADRAO = false;
        delete data.ID_RELATORIO_CUSTOM;
      }
      data.customReportFields = data.customReportFields.map((customReportField) => {
        delete customReportField.ID_RELATORIO_CUSTOM_CAMPO;
        delete customReportField.ID_RELATORIO_CUSTOM;
        customReportField.customReportFieldValues = customReportField.customReportFieldValues?.map((customFieldValue) => {
          delete customFieldValue.ID_RELAT_CUSTOM_CAMPO_VALOR;
          delete customFieldValue.ID_RELATORIO_CUSTOM_CAMPO;
          return customFieldValue;
        })
        return customReportField;
      });

      await saveCustomReport(data);
      enqueueSnackbar("Custom Report created successful", { variant: "success" });
      window.location.reload();
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        setErrors(err.response?.data?.message);
      }
    }
  };

  const handleDelete = async () => {
    setErrors([]);
    try {
      await deleteCustomReport(reportSelected.id);
      enqueueSnackbar("Custom Report deleted successful", { variant: "success" });
      reset({
        ID_RELATORIO: ID_RELATORIO,
        FILTRO_PADRAO: false,
        customReportFields: [],
      });
      window.location.reload();
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        setErrors(err.response?.data?.message);
      }
    }
  };

  const onError = (error, data) => {
    console.error('Error');
    console.log(data);
    console.log(error);
  };

  const isNewRegister = () => {
    if(nomeCustom === undefined || nomeCustom === '') {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if(reportSelected) {
      findCustomReportStructure(reportSelected.id).then((response) => {
        response.NOME_CUSTOM = '';
        reset(response)
      });
    }
  }, [reportSelected])

  let mainTable;
  if(ID_RELATORIO === 7) {
    mainTable = {
      tableName: 'GTS_PROCESSO_EMBARQUE',
      columnName: 'processoEmbarque',
      relation: '',
      tableNameOrigin: '',
      columnNameOrigin: '',
      path: 'processoEmbarque'
    } 
  } else if(ID_RELATORIO === 10) {
    mainTable = {
      tableName: 'GTS_TRIAGEM',
      columnName: 'triageInvoices',
      relation: '',
      tableNameOrigin: '',
      columnNameOrigin: '',
      path: 'triageInvoices'
    }
  } else {
    mainTable = {
      tableName: 'GTS_INVOICE',
      columnName: 'invoice',
      relation: '',
      tableNameOrigin: '',
      columnNameOrigin: '',
      path: 'invoice'
    }
  }

  return (
    <Grid marginTop={4}>
      <Grid container spacing={1} direction="column">
        <Grid item xs={10}>
          <Typography variant="h5">Custom Report</Typography>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={2} marginBottom={2} marginTop={4}>
            <Grid item md={6} xs={12}>
              <Autocomplete
                options={customReportCombo}
                getOptionLabel={(option) => option.description}
                value={ reportSelected }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Model"
                    size="small"
                    variant="outlined"
                    data-testid="company-plant-input"
                  />
                )}
                onChange={(_: any, data: any) => setReportSelected(data)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              {(reportSelected?.description !== 'Default' && reportSelected?.description) &&
                <Button
                  sx={{ minWidth: 0 }}
                  color="error"
                  onClick={() => setOpenDeleteModal(true)}
                >
                  <DeleteIcon />
                </Button>
              }
            </Grid>
            
            <Grid item xs={12}>
              {!isLoading && <CustomReportTable
                relation={mainTable}
                entities={entities}
                path={mainTable.path}
                startOrder={0}
              />}
            </Grid>
            <Grid item md={6} xs={8}>
              <TextField
                {...register('NOME_CUSTOM')}
                error={!!formErrors.NOME_CUSTOM}
                data-testid="description-input"
                fullWidth
                label="Name"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 60 }}
                InputLabelProps={{ shrink: true }}
                onChange={(event) => setNomeCustom(event.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={4} display='flex' gap={2}>
              <Button
                data-testid="submit-button"
                onClick={() => setCustomReport(false)}
              >
                Return
              </Button>
              <Button
                variant="contained"
                type="submit"
                data-testid="submit-button"
                disabled={ !isNewRegister() && !customReportId }
              >
                { isNewRegister() ? 'Create' : 'Save' }
              </Button>
            </Grid>
          </Grid>
        </form>
        <Dialog
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Custom Report</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure that you want to delete this Custom Report?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
            <Button onClick={handleDelete}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
}