import {
  Alert,
  Autocomplete,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { useSnackbar } from "notistack";
import { exportAll, findCenterCombo, findCustomReport, findMfir } from "../../../../../services/report";
import { ComboBoxType } from "../../../../../types/general";
import AddIcon from '@mui/icons-material/Add';
import { Download } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface FormGeneralProps {
  setParams: (params: any) => void;
  setIsLoading: (value: boolean) => void;
  isLoading?: boolean;
  errors?: string[];
  ID_RELATORIO: number;
  setCustomReport: (value: boolean) => void;
  reportSelected: ComboBoxType;
  setReportSelected: (comboSelected: ComboBoxType) => void;
  customReportCombo: ComboBoxType[];
  setCustomReportCombo: (combo: ComboBoxType[]) => void;
}

export default function FormInvoiceMigo({
  setIsLoading,
  setParams,
  isLoading = false,
  errors = [],
  ID_RELATORIO,
  setCustomReport,
  reportSelected,
  setReportSelected,
  customReportCombo,
  setCustomReportCombo,
}: FormGeneralProps) {
  const [centerSelected, setCenterSelected] = useState();
  const [centerCombo, setCenterCombo] = useState<ComboBoxType[]>([]);
  const [isLoadingCenter, setIsLoadingCenter] = useState(true);
  const [isLoadingReportCustom, setIsLoadingReportCustom] = useState(true);
  const [ reportInitialDate, setReportInitialDate ] = useState();
  const [ reportEndDate, setReportEndDate ] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const handleFormSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if(!reportSelected?.id) {
      enqueueSnackbar("You must to select Report", {
        variant: "warning",
      });
      return;
    }

    if(!reportInitialDate || !reportEndDate) {
      enqueueSnackbar("You must to selete Date Initial and Date Final", {
        variant: "warning",
      });
      return;
    }
  
    setParams((prevState: any) => ({
      ...prevState,
      count: prevState.count + 1,
      page: 1,
      ID_RELATORIO_CUSTOM: reportSelected?.id,
      ID_CENTRO: centerSelected,
      INITIAL_DATE: reportInitialDate,
      FINAL_DATE: reportEndDate,
    }));
  }

  const exportFile = async () => {
    if(!reportSelected?.id) {
      enqueueSnackbar("You must to select Report", {
        variant: "warning",
      });
      return;
    }

    if(!reportInitialDate || !reportEndDate) {
      enqueueSnackbar("You must to selete Date Initial and Date Final", {
        variant: "warning",
      });
      return;
    }

    setIsLoading(true);
    try {
      const response = await exportAll({
        ID_RELATORIO_CUSTOM: reportSelected?.id,
        ID_CENTRO: centerSelected,
        INITIAL_DATE: reportInitialDate,
        FINAL_DATE: reportEndDate,
      });

      if(!reportInitialDate || !reportEndDate) {
        enqueueSnackbar("You must to selete Date Initial and Date Final", {
          variant: "warning",
        });
        return;
      }

      const contentDisposition = response.headers.get('Content-Disposition');
      let filename = `${reportSelected.description}.xlsx`;

      if (contentDisposition && contentDisposition.includes('filename=')) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (filenameMatch.length > 1) {
          filename = filenameMatch[1];
        }
      }

      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${reportSelected.description}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (err) {}
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Buscar custom report
        const customReportResponse = await findCustomReport(ID_RELATORIO);
        setCustomReportCombo(customReportResponse);

        // Encontrar o item 'Default'
        const founded = customReportResponse?.findLast((item) => item.description === 'Default');
        if (founded) {
          setReportSelected(founded);
        }

        setIsLoadingReportCustom(false);

        // Buscar center combo
        const centerResponse = await findCenterCombo();
        setCenterCombo(centerResponse);
        setIsLoadingCenter(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoadingReportCustom(false);
        setIsLoadingCenter(false);
      }
    };

    fetchData();
  }, [ID_RELATORIO]);

  return (
    <form
      style={{ width: "100%" }}
      data-testid="report-form"
    >
      {errors.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid container spacing={2} marginBottom={2} marginTop={4}>
        <Grid item md={10} xs={10} display='flex' justifyContent='end'>
          <Button
            startIcon={<AddIcon />}
            variant='contained'
            size='small'
            onClick={() => setCustomReport(true)}
          >
            Custom
          </Button>
        </Grid>
        <Grid item md={5} xs={5}>
          <Autocomplete
            options={centerCombo || []}
            getOptionLabel={(option) => option.description}
            loading={isLoadingCenter}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Company - Plant"
                size="small"
                variant="outlined"
                data-testid="company-plant-input"
              />
            )}
            onChange={(_: any, data: any) => setCenterSelected(data?.id)}
          />
        </Grid>
        <Grid item md={5} xs={5}>
          <Autocomplete
            options={customReportCombo || []}
            getOptionLabel={(option) => option.description}
            value={ reportSelected }
            loading={isLoadingReportCustom}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Custom Report"
                size="small"
                variant="outlined"
                data-testid="custom-report-input"
                // defaultValue={() => {}}
              />
            )}
            onChange={(_: any, data: any) => setReportSelected(data)}
          />
        </Grid>
        <Grid item md={2} xs={2}>
          <Button
            startIcon={<SearchIcon />}
            variant="outlined"
            disabled={isLoading}
            data-testid="submit-button"
            onClick={handleFormSearch}
          >
            Search
          </Button>
        </Grid>
        <Grid item md={6} xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                "& .MuiInputBase-root": { height: "39px" },
                "& .MuiFormLabel-root": { top: "-7px" },
                marginRight: 2,
              }}
              label='Date Initial'
              slotProps={{
                textField: {
                  variant: "outlined",
                },
              }}
              onChange={(date: any) => setReportInitialDate(date)}
            />
            <DatePicker
              sx={{
                "& .MuiInputBase-root": { height: "39px" },
                "& .MuiFormLabel-root": { top: "-7px" },
              }}
              label='Date Final'
              slotProps={{
                textField: {
                  variant: "outlined",
                },
              }}
              onChange={(date: any) => setReportEndDate(date)}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid container marginTop={4} justifyContent="flex-end">
        <Button
          variant="text"
          onClick={exportFile}
          startIcon={<Download />}
          disabled={isLoading}
        >
          Export XLSX
        </Button>
      </Grid>
    </form>
  );
}
