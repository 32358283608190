/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-undef */
import { isAxiosError } from "axios";
import { useEffect, useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import UndoIcon from '@mui/icons-material/Undo';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Alert, Autocomplete, Button, FormControlLabel, Grid, IconButton, Paper, Switch, TextField, Typography } from "@mui/material";
import * as yup from "yup";
import { useList as useCenter } from "../../../../services/centers";
import { listFreightForward } from "../../../../services/consolidationCenter";
import { useList as useServiceLevel } from "../../../../services/serviceLevel";
import { useList as useAirport } from "../../../../services/airport";
import { useList as useShipmentInstruction } from "../../../../services/shipment";
import { findBoardingAuthorizationById, useList as useListInvoice } from "../../../../services/invoice";
import { create, update, get as getBoardingAuthorization } from "../../../../services/boardingAuthorization";
import { getLocal, getToken } from "../../../../storage/auth";
import { BoardingAuthorization } from "../../../../types/boardingAuthorization";
import { GridActionsCellItem, GridColDef, GridExpandMoreIcon } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { Accordion } from "../../../../components/Accordion/Accordion";
import { AccordionSummary } from "../../../../components/Accordion/AccordionSummary";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DataTableDefault from "../../../../components/DataTable";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DroppableArea from "./components/DroppableArea";
import { findReadyToShip } from "../../../../services/warehouse";
import { useNavigate, useParams } from "react-router-dom";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import dayjs from "dayjs";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
}

const IncotermsFreightType = [
  { id: "COLLECT", description: "COLLECT" },
  { id: "PREPAID", description: "PREPAID" }
]

const shipmentAuthorizationSchema = yup.object({
  ID_AUTORIZACAO_EMBARQUE: yup.number(),
  ID_AEROPORTO_ORIGEM: yup.object({
    id: yup.number().required(),
  }),
  ID_AEROPORTO_DEST_PRINCIPAL: yup.object({
    id: yup.number().required(),
  }),
  ID_AEROPORTO_DEST_ALTERNATIVO: yup.object({
    id: yup.number().required(),
  }),
  DSC_OBSERVACAO_AUTORIZ: yup.string(),
  DATA_AUTORIZACAO: yup.string(),
  ID_CENTRO: yup.object({
    id: yup.number().required(),
  }),
  ID_PRIORIDADE: yup.object({
    id: yup.number().required(),
  }),
  ID_INSTRUCAO_EMBARQUE: yup.object({
    id: yup.number().required(),
  }),
  NUM_REVISAO: yup.number(),
  ID_CENTRO_CONSOLIDADOR: yup.object({
    id: yup.number().required(),
  }),
  ID_FRETE_INCO: yup.object({
    id: yup.string().required(),
  }),
  ID_MODAL: yup.object({
    id: yup.string().required(),
  })
});

export default function ShipmentAuthorization() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id: idBoardingAuthorization } = useParams<{ id: number | any }>();
  const [boardingAuthorization, setBoardingAuthorization] = useState<BoardingAuthorization>({} as BoardingAuthorization);
  const [token, setToken] = useState<string>();
  const [search, setSearch] = useState<BoardingAuthorization["ID_AUTORIZACAO_EMBARQUE"] | null>();
  const [consolidationCenterCombo, setConsolidationCenterCombo] = useState([]);
  const [warehouse, setWarehouse] = useState<any>({ search: '', group: false });
  const [isSaving, setIsSaving] = useState(false);
  const [airportId, setAirportId] = useState<number | null>(null);
  const [userValidation, setUserValidation] = useState<any>();

  const [availableItems, setAvailableItems] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const [shipments, setShipments] = useState<any[]>([]);
  const [currentShipments, setCurrentShipments] = useState<any[]>([]);
  const [dropShipments, setDropShipments] = useState<any[]>([]);

  const toggleItems = async (setSourceItems: any, setDestinationItems: any) => {
    const items = [...availableItems, ...selectedItems];
    await setDestinationItems(items);
    setSourceItems([]);
  };

  const getTokenSession = async () => {
    const getTokens = (await getToken()) as any;
    const getUser = (await getLocal("userInfo")) as any;
    setToken(getTokens);
    setUserValidation(JSON.parse(getUser));
  };

  const fetchConsolidationCenter = async () => {
    const consolidationCenterCombo = await listFreightForward();
    setConsolidationCenterCombo(consolidationCenterCombo || []);
  }

  useEffect(() => {
    getTokenSession();
    fetchConsolidationCenter();
  }, []);

  const [params] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const [shipmentInstructionParams, setShipmentInstructionParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const [errors, setErrors] = useState<string[]>();

  const { transportationMode } = useListInvoice(token);
  const { combo: dataCenterCombo } = useCenter(
    params,
    token,
    true
  );

  const { combo: serviceLevelCombo } = useServiceLevel(params, token, true);
  const { combo: airportCombo } = useAirport(params, token, true);
  const { combo: shipmentInstructionCombo } = useShipmentInstruction(
    shipmentInstructionParams,
    token,
    true
  );

  const {
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    unregister,
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(shipmentAuthorizationSchema),
  });

  const handleDeleteWarehouseConfirmation = (row: any) => {
    const { ID_WAREHOUSE } = row || {};
    const newItems = currentShipments.filter((shipment: any) => shipment?.warehouse?.ID_WAREHOUSE !== ID_WAREHOUSE);
    const dropItems = currentShipments.filter((shipment: any) => shipment?.warehouse?.ID_WAREHOUSE === ID_WAREHOUSE);
    setCurrentShipments(newItems);
    setDropShipments((oldState) => ([...oldState, ...dropItems]));
  };

  const handleFormSubmit = async (newShipment: any) => {
    const invoiceIds: any[] = [];
    const removeInvoiceIds: any[] = [];
    dropShipments?.forEach(({ invoiceIds }: any) => {
      invoiceIds.forEach((invoice: any) => {
        removeInvoiceIds.push(invoice);
      });
    });
    selectedItems?.forEach(({ INVOICE_IDS }: any) => {
      INVOICE_IDS.forEach((invoice: any) => {
        invoiceIds.push(invoice);
      });
    });

    const payload = {
      ...newShipment,
      ID_AEROPORTO_DEST_ALTERNATIVO: newShipment?.ID_AEROPORTO_DEST_ALTERNATIVO?.id,
      ID_AEROPORTO_DEST_PRINCIPAL: newShipment?.ID_AEROPORTO_DEST_PRINCIPAL?.id,
      ID_AEROPORTO_ORIGEM: newShipment?.ID_AEROPORTO_ORIGEM?.id,
      ID_CENTRO: newShipment?.ID_CENTRO?.id,
      ID_CENTRO_CONSOLIDADOR: newShipment?.ID_CENTRO_CONSOLIDADOR?.id,
      ID_FRETE_INCO: newShipment?.ID_FRETE_INCO?.id,
      ID_INSTRUCAO_EMBARQUE: newShipment?.ID_INSTRUCAO_EMBARQUE?.id,
      ID_MODAL: newShipment?.ID_MODAL?.id,
      ID_PRIORIDADE: newShipment?.ID_PRIORIDADE?.id,
      ID_LOGIN_USUARIO: userValidation?.id,
      invoiceIds,
      removeInvoiceIds,
    };

    if (!payload?.ID_AUTORIZACAO_EMBARQUE && payload?.invoiceIds.length <= 0) {
      enqueueSnackbar("At least one Warehouse must be selected on WR's To Send list to be authorized", { variant: "error" });
      return;
    }

    try {
      setErrors([]);
      setIsSaving(true);

      let ID_AUTORIZACAO_EMBARQUE = payload?.ID_AUTORIZACAO_EMBARQUE;
      if (payload?.ID_AUTORIZACAO_EMBARQUE) {
        await update(payload.ID_AUTORIZACAO_EMBARQUE, payload);
        enqueueSnackbar("Shipment Authorization updated successful", {
          variant: "success",
        });

        unregister();
        setBoardingAuthorization({} as BoardingAuthorization);
        setCurrentShipments([]);
        setDropShipments([]);
        setSelectedItems([]);
        setAvailableItems([]);
      } else {
        const response = await create(payload);
        ID_AUTORIZACAO_EMBARQUE = response?.ID_AUTORIZACAO_EMBARQUE;
        enqueueSnackbar("Shipment Authorization created successful", {
          variant: "success",
        });
        reset();
        setSearch(ID_AUTORIZACAO_EMBARQUE);
        findSearchFromParam(ID_AUTORIZACAO_EMBARQUE);
      }
      navigate('/dashboard')
      setIsSaving(false);

    } catch (err: unknown) {
      if (isAxiosError(err)) {
        setErrors([err.response?.data?.message]);
      }
      setIsSaving(false);
    }
    setIsSaving(false);
  };

  const columns: GridColDef[] = [
    {
      field: "NUM_WAREHOUSE",
      headerName: "WR",
      headerClassName: "dashboard-header",
      flex: 1,
    },
    {
      field: "NUM_INVOICE",
      headerName: "INVOICES",
      headerClassName: "dashboard-header",
      flex: 1,
    },
    {
      field: "CENTRO_CONSOLIDADOR",
      headerName: "CONSOLIDATION CENTER",
      headerClassName: "dashboard-header",
      flex: 1,
    },
    {
      field: "GOVERMENT_AUTHORITY",
      headerName: "GOVERMENT AUTHORITY",
      headerClassName: "dashboard-header",
      flex: 1,
    },
    {
      field: "ID_MODAL",
      headerName: "TRANSPORTATION MODE",
      headerClassName: "dashboard-header",
      flex: 1,
    },
    {
      field: "MATERIAL_TYPE",
      headerName: "MATERIAL TYPE",
      headerClassName: "dashboard-header",
      flex: 1,
    },
    {
      field: "CUSTOMER_REGIME",
      headerName: "CUSTOMER REGIME",
      headerClassName: "dashboard-header",
      flex: 1,
    },
    {
      field: "INCOTERM",
      headerName: "INCOTERMS",
      headerClassName: "dashboard-header",
      flex: 1,
    },
    {
      field: "PRIORIDADE",
      headerName: "SERVICE LEVEL",
      headerClassName: "dashboard-header",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "dashboard-header",
      sortable: false,
      width: 80,
      getActions: ({ row }) => ([
        <GridActionsCellItem
          sx={{
            display: !row?.ID_PROCESSO_EMBARQUE
              ? "block"
              : "none",
          }}
          color="error"
          size="medium"
          key={`delete-${row?.ID_WAREHOUSE}`}
          icon={<AutoFixNormalIcon />}
          label="Delete"
          onClick={() => handleDeleteWarehouseConfirmation(row)}
        />
      ]),
    },
  ];

  const loadInvoices = async(isDisabledBoardingProcess: boolean) => {
    let items: any[] = [];
    if (!isDisabledBoardingProcess) {
      const { ID_CENTRO, ID_CENTRO_CONSOLIDADOR, ID_FRETE_INCO, ID_MODAL } = control?._formValues || {};
      if (!ID_CENTRO || (!ID_CENTRO_CONSOLIDADOR && !ID_FRETE_INCO)) return;

      const { search: NUM_WAREHOUSE } = warehouse || {};

      items = await findReadyToShip({
        ID_CENTRO: ID_CENTRO?.id,
        ID_CENTRO_CONSOLIDADOR: ID_CENTRO_CONSOLIDADOR?.id,
        ID_FRETE_INCO: ID_FRETE_INCO?.id,
        ID_MODAL: ID_MODAL?.id,
        NUM_WAREHOUSE,
        group: warehouse?.group,
      });
    }

    setAvailableItems(items);
    setSelectedItems([]);
  }

  const findSearch = async () => {
    findSearchFromParam(search!);
  }

  const findSearchFromParam = async (searchString: number) => {
    setErrors([]);
    await setBoardingAuthorization({} as BoardingAuthorization);
    if (!searchString) return;

    try {
      const item = await getBoardingAuthorization(searchString);
      await setBoardingAuthorization(item);

    } catch (error) {
      enqueueSnackbar("It's not posible to edit, try again later", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  const onReturn = async () => {
    setCurrentShipments(shipments);
    setDropShipments([]);
  }

  const fetchShipments = async () => {
    if (!control?._formValues?.ID_AUTORIZACAO_EMBARQUE) return;
    const items: any[] = await findBoardingAuthorizationById(control?._formValues?.ID_AUTORIZACAO_EMBARQUE);
    setShipments(items);
    setCurrentShipments(items);
    setDropShipments([]);
  }

  const loadData = async () => {
    if (idBoardingAuthorization && shipmentInstructionCombo?.length > 0) {
      setIsLoading(true);
      if (!search) return setSearch(idBoardingAuthorization);
      findSearch();
    }
  }

  useEffect(() => {
    const consolidationCenterComboMapped = consolidationCenterCombo?.map((item: any) => ({ ...item, id: item.ID_CENTRO_CONSOLIDADOR, description: `${item?.pais?.SIGLA_PAIS || ''} - ${item.NOME}` }));

    const ID_CENTRO = dataCenterCombo?.find((item: { id: number; }) => item.id === boardingAuthorization?.ID_CENTRO);
    const ID_CENTRO_CONSOLIDADOR = consolidationCenterComboMapped?.find((item: { ID_CENTRO_CONSOLIDADOR: number; }) => item.ID_CENTRO_CONSOLIDADOR === boardingAuthorization?.ID_CENTRO_CONSOLIDADOR);
    const ID_INSTRUCAO_EMBARQUE = shipmentInstructionCombo?.find((item: { id: number; }) => item.id === boardingAuthorization?.ID_INSTRUCAO_EMBARQUE);
    const ID_PRIORIDADE = serviceLevelCombo?.find((item: { id: number; }) => item.id === boardingAuthorization?.ID_PRIORIDADE);
    const ID_AEROPORTO_DEST_ALTERNATIVO = airportCombo?.find((item: { id: number; }) => item.id === boardingAuthorization?.ID_AEROPORTO_DEST_ALTERNATIVO);
    const ID_AEROPORTO_DEST_PRINCIPAL = airportCombo?.find((item: { id: number; }) => item.id === boardingAuthorization?.ID_AEROPORTO_DEST_PRINCIPAL);
    const ID_AEROPORTO_ORIGEM = airportCombo?.find((item: { id: number; }) => item.id === boardingAuthorization?.ID_AEROPORTO_ORIGEM);
    const ID_MODAL = transportationMode?.find((item: { description: string; }) => item.description === boardingAuthorization?.ID_MODAL);
    const ID_FRETE_INCO = IncotermsFreightType?.find((item: { description: string; }) => item.description === boardingAuthorization?.ID_FRETE_INCO);

    const body: any = {
      ...boardingAuthorization,
      ID_MODAL,
      ID_CENTRO,
      ID_CENTRO_CONSOLIDADOR,
      ID_PRIORIDADE,
      ID_AEROPORTO_ORIGEM,
      ID_AEROPORTO_DEST_PRINCIPAL,
      ID_AEROPORTO_DEST_ALTERNATIVO,
      ID_FRETE_INCO,
      ID_INSTRUCAO_EMBARQUE
    };

    reset(body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, boardingAuthorization]);

  const isDisabledBoardingProcess = useMemo(() => {
    return (control?._formValues?.ID_AUTORIZACAO_EMBARQUE && currentShipments?.length > 0 && currentShipments?.every(item => item.boardingProcessIds?.every((value: number | null) => typeof value === 'number' && value > 1))) || false;
  }, [control?._formValues?.ID_AUTORIZACAO_EMBARQUE, currentShipments]);

  useEffect(() => {
    setTimeout(() => {
      loadInvoices(isDisabledBoardingProcess);
    }, 10);
  }, [
    control?._formValues?.ID_CENTRO,
    control?._formValues?.ID_CENTRO_CONSOLIDADOR,
    control?._formValues?.ID_FRETE_INCO,
    control?._formValues?.ID_MODAL,
    warehouse?.group,
    isDisabledBoardingProcess
  ]);

  useEffect(() => {
    fetchShipments();
  }, [control?._formValues?.ID_AUTORIZACAO_EMBARQUE]);

  useEffect(() => {
    loadData();
  }, [idBoardingAuthorization, search, shipmentInstructionCombo]);

  useEffect(() => {
    if (!idBoardingAuthorization && airportId) {
      setValue('ID_AEROPORTO_ORIGEM', airportCombo?.find((item: { id: { toString: () => string; }; }) => item.id.toString() === airportId.toString()), { shouldValidate: true });
    }
  }, [airportId]);

  return (
    <Grid marginTop={4}>
      <LoadingOverlay isLoading={isLoading} />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <Typography variant="h5">Shipment Authorization</Typography>
        </Grid>
      </Grid>

      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        data-testid="boarding-authorization-search"
      >
        {errors?.map((error) => (
          <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
            {error}
          </Alert>
        ))}

        <Grid container spacing={2} alignItems="center" marginTop={1}>
          <Grid item xs={12}>
            <Paper
              variant='outlined'
              sx={{ paddingTop: 2, paddingLeft: 5, paddingBottom: 2, paddingRight: 15 }}
            >
              <Grid container spacing={2} alignItems="center" marginTop={1}>
                <Grid item xs={4}>
                  <TextField
                    name="search"
                    value={search || ''}
                    data-testid="search-input"
                    fullWidth
                    label="Shipment Authorization*"
                    variant="outlined"
                    size="small"
                    disabled={!!idBoardingAuthorization || !!boardingAuthorization.ID_AUTORIZACAO_EMBARQUE || isDisabledBoardingProcess}
                    onChange={(value: any) => setSearch(value?.target?.value)}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Button
                    sx={{ marginLeft: 2 }}
                    variant="contained"
                    onClick={() => findSearch()}
                    disabled={isSaving || !search || !!idBoardingAuthorization || isDisabledBoardingProcess}
                  >
                    <SearchIcon />
                  </Button>
                </Grid>
                {!!boardingAuthorization.DATA_AUTORIZACAO && (
                  <Grid item xs={6} container alignItems="center" justifyContent="flex-end">
                    <TextField
                      label="Authorization Date"
                      size="small"
                      variant="outlined"
                      disabled
                      value={dayjs(boardingAuthorization.DATA_AUTORIZACAO).format('MM/DD/YYYY HH:mm:ss')}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={2} alignItems="center" marginTop={1}>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="ID_CENTRO"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={dataCenterCombo || []}
                        getOptionLabel={(option: any) => option.description}
                        disabled={!!boardingAuthorization?.ID_AUTORIZACAO_EMBARQUE || isDisabledBoardingProcess}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Center/Plant"
                            required
                            size="small"
                            variant="outlined"
                            error={!!formErrors?.ID_CENTRO}
                          />
                        )}
                        onChange={(_, data: any) => {
                          field.onChange(data);
                          setValue('ID_CENTRO', data, { shouldValidate: true });
                          setShipmentInstructionParams((oldState) => ({ ...oldState, ID_CENTRO: data?.id }));
                        }}
                        value={field.value || null}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems="center" marginTop={1}>
                <Grid item xs={3}>
                  <Controller
                    control={control}
                    name="ID_MODAL"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={transportationMode}
                        disabled={isDisabledBoardingProcess}
                        getOptionLabel={(option: any) => option?.description}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Transportation Mode"
                            required
                            size="small"
                            variant="outlined"
                            error={!!formErrors?.ID_MODAL}
                          />
                        )}
                        onChange={(_, data: any) => {
                          field.onChange(data);
                          setValue('ID_MODAL', data, { shouldValidate: true });
                        }}
                        value={field.value || null}
                      />
                    )}

                  />
                </Grid>

                <Grid item xs={3}>
                  <Controller
                    control={control}
                    name="ID_PRIORIDADE"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={serviceLevelCombo}
                        disabled={isDisabledBoardingProcess}
                        getOptionLabel={(option: any) => option.description}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Service Level"
                            size="small"
                            variant="outlined"
                            error={!!formErrors?.ID_PRIORIDADE}
                          />
                        )}
                        onChange={(_, data: any) => {
                          field.onChange(data);
                          setValue('ID_PRIORIDADE', data, { shouldValidate: true });
                        }}
                        value={field.value || null}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name="ID_CENTRO_CONSOLIDADOR"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={consolidationCenterCombo?.map((item: any) => ({ ...item, id: item.ID_CENTRO_CONSOLIDADOR, description: `${item?.pais?.SIGLA_PAIS || ''} - ${item.NOME}`}))}
                        getOptionLabel={(option: any) => option.description}
                        disabled={!!boardingAuthorization?.ID_AUTORIZACAO_EMBARQUE || isDisabledBoardingProcess}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Consolidation Center"
                            size="small"
                            variant="outlined"
                            error={!!formErrors?.ID_CENTRO_CONSOLIDADOR}
                          />
                        )}
                        onChange={(_, data: any) => {
                          field.onChange(data);
                          setValue('ID_CENTRO_CONSOLIDADOR', data, { shouldValidate: true });
                          setAirportId(data?.ID_AEROPORTO);
                        }}
                        value={field.value || null}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Controller
                    control={control}
                    name="ID_FRETE_INCO"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={IncotermsFreightType}
                        getOptionLabel={(option: any) => option.description}
                        disabled={!!boardingAuthorization?.ID_AUTORIZACAO_EMBARQUE || isDisabledBoardingProcess}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Freight"
                            size="small"
                            variant="outlined"
                            error={!!formErrors?.ID_FRETE_INCO}
                          />
                        )}
                        onChange={(_, data: any) => {
                          field.onChange(data);
                          setValue('ID_FRETE_INCO', data, { shouldValidate: true });
                        }}
                        value={field.value || null}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems="center" marginTop={1}>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name="ID_AEROPORTO_ORIGEM"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={airportCombo}
                        disabled={isDisabledBoardingProcess}
                        getOptionLabel={(option: any) => option.description}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Port/Origin Airport"
                            required
                            size="small"
                            variant="outlined"
                            error={!!formErrors?.ID_AEROPORTO_ORIGEM}
                          />
                        )}
                        onChange={(_, data: any) => {
                          field.onChange(data);
                          setValue('ID_AEROPORTO_ORIGEM', data, { shouldValidate: true });
                        }}
                        value={field.value || null}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name="ID_AEROPORTO_DEST_PRINCIPAL"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={airportCombo}
                        disabled={isDisabledBoardingProcess}
                        getOptionLabel={(option: any) => option.description}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Primary Destination"
                            required
                            size="small"
                            variant="outlined"
                            error={!!formErrors?.ID_AEROPORTO_DEST_PRINCIPAL}
                          />
                        )}
                        onChange={(_, data: any) => {
                          field.onChange(data);
                          setValue('ID_AEROPORTO_DEST_PRINCIPAL', data, { shouldValidate: true });
                        }}
                        value={field.value || null}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name="ID_AEROPORTO_DEST_ALTERNATIVO"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={airportCombo}
                        disabled={isDisabledBoardingProcess}
                        getOptionLabel={(option: any) => option.description}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Secondary Destination"
                            required
                            size="small"
                            variant="outlined"
                            error={!!formErrors?.ID_AEROPORTO_DEST_ALTERNATIVO}
                          />
                        )}
                        onChange={(_, data: any) => {
                          field.onChange(data);
                          setValue('ID_AEROPORTO_DEST_ALTERNATIVO', data, { shouldValidate: true });
                        }}
                        value={field.value || null}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems="center" marginTop={1}>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name="ID_INSTRUCAO_EMBARQUE"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={shipmentInstructionCombo}
                        disabled={isDisabledBoardingProcess}
                        getOptionLabel={(option: any) => option.description}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Shipment Instruction"
                            required
                            size="small"
                            variant="outlined"
                            error={!!formErrors?.ID_INSTRUCAO_EMBARQUE}
                          />
                        )}
                        onChange={(_, data: any) => {
                          field.onChange(data);
                          setValue('ID_INSTRUCAO_EMBARQUE', data, { shouldValidate: true });
                        }}
                        value={field.value || null}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={8}>
                  <Controller
                    name="DSC_OBSERVACAO_AUTORIZ"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        {...params}
                        label="Notes"
                        size="small"
                        style={{ width: '100%' }}
                        multiline
                        InputLabelProps={{ shrink: true }}
                        rows={2}
                        error={!!formErrors?.DSC_OBSERVACAO_AUTORIZ}
                        defaultValue={boardingAuthorization?.DSC_OBSERVACAO_AUTORIZ || ''}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid xs={6}>
                  <Button
                    sx={{
                      backgroundColor: "rgba(0, 0, 0, 0.2)",
                      color: "currentColor",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        color: "currentColor",
                      },
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate('/dashboard')}
                  >
                    Return
                  </Button>
                </Grid>
                <Grid xs={6}
                  marginTop={1}
                  alignItems="center"
                  justifyContent="flex-end"
                  container
                >
                  <Button
                    variant="contained"
                    type="submit"
                    data-testid="submit-button"
                  >
                    {boardingAuthorization?.ID_AUTORIZACAO_EMBARQUE ? 'Save' : 'Authorize'}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </form>

      {boardingAuthorization?.ID_AUTORIZACAO_EMBARQUE && (
        <Accordion
          sx={{
            '&.MuiAccordion-root': { border: 0, marginBottom: 3, marginTop: 1 },
          }}
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<GridExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography>WAREHOUSE RECEIPT</Typography>
          </AccordionSummary>

          <Grid container justifyContent="flex-end">
            <Grid item>
              <IconButton onClick={onReturn} aria-label="undo">
                <UndoIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <DataTableDefault
                rowId={currentShipments?.map((row) => row.NUM_INVOICE)}
                data-testid="custom-regime-data-table"
                data={currentShipments?.map(({
                  invoices,
                  invoiceIds,
                  ID_MODAL,
                  govermentAuthority,
                  warehouse,
                  incoterms,
                  customsRegime,
                  serviceLevel
                }) => ({
                  ID_WAREHOUSE: warehouse?.ID_WAREHOUSE,
                  NUM_WAREHOUSE: warehouse?.NUM_WAREHOUSE,
                  MATERIAL_TYPE: warehouse?.materialType?.DESCRICAO,
                  CENTRO_CONSOLIDADOR: `${warehouse?.consolidationCenter?.pais?.SIGLA_PAIS || ''} - ${warehouse?.consolidationCenter?.NOME} `,
                  GOVERMENT_AUTHORITY: govermentAuthority?.DSC_ORG_ANUENTE,
                  CUSTOMER_REGIME: customsRegime?.DESCRICAO_REGIME,
                  INCOTERM: incoterms?.SIGLA,
                  PRIORIDADE: serviceLevel?.DSC_PRIORIDADE,
                  NUM_INVOICE: invoices.join(', '),
                  ID_MODAL,
                  invoiceIds,
                }))}
                columns={columns}
              />
            </Grid>
          </Grid>
        </Accordion>
      )}

      <Grid container spacing={2} alignItems="center" marginTop={1}>
        <Grid item xs={12}>
          <Paper
            variant='outlined'
            sx={{ paddingTop: 2, paddingLeft: 5, paddingBottom: 2, paddingRight: 5 }}
          >
            <Grid container spacing={2} alignItems="center" marginTop={1}>
              <Grid item xs={4}>
                <Typography variant="h6">
                  Warehouse ID
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center" marginTop={1}>
              <Grid item xs={3}>
                <TextField
                  value={warehouse.search}
                  data-testid="description-input"
                  fullWidth
                  label="Search*"
                  variant="outlined"
                  size="small"
                  onChange={(value) => setWarehouse((oldState: any) => ({ ...oldState, search: value.target.value }))}
                />
              </Grid>

              <Grid item xs={1}>
                <FormControlLabel
                  control={
                    <Switch
                      inputProps={{ "aria-label": "controlled" }}
                      checked={!!warehouse?.group}
                    />
                  }
                  labelPlacement="top"
                  label="Group"
                  onChange={() => setWarehouse((oldState: any) => ({ ...oldState, group: !oldState.group }))}
                />
              </Grid>

              <Grid item xs={2}>
                <Button
                  sx={{ marginLeft: 2 }}
                  variant="contained"
                  onClick={() => loadInvoices(false)}
                  disabled={isSaving}
                >
                  <SearchIcon />
                </Button>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center" marginTop={1}>
              <Grid item xs={5}>
                <Typography
                  sx={{ fontWeight: "bold" }}
                  variant="body2"
                >
                  WR's Ready To Ship
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <IconButton
                  style={{ borderRadius: '24px', border: '1px solid #1976d2', marginRight: 10 }}
                  aria-label='Move All To Send'
                  size='small'
                  onClick={() => toggleItems(setAvailableItems, setSelectedItems)}
                >
                  <KeyboardDoubleArrowRightIcon style={{ color: '#1976d2' }} />
                </IconButton>

                <IconButton
                  style={{ borderRadius: '24px', border: '1px solid #1976d2' }}
                  aria-label='Remove All From Send'
                  size='small'
                  onClick={() => toggleItems(setSelectedItems, setAvailableItems)}
                >
                  <KeyboardDoubleArrowLeftIcon style={{ color: '#1976d2' }} />
                </IconButton>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  sx={{ fontWeight: "bold" }}
                  variant="body2"
                >
                  WR's To Send
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="start" marginTop={1}>
              <DndProvider backend={HTML5Backend}>
                <Grid item xs={5}>
                  <DroppableArea
                    items={availableItems}
                    total={availableItems}
                    setItems={setAvailableItems}
                    dropItems={setSelectedItems}
                    consolidationCenters={consolidationCenterCombo}
                  />
                </Grid>

                <Grid item xs={2}></Grid>

                <Grid item xs={5}>
                  <DroppableArea
                    items={selectedItems}
                    total={availableItems}
                    setItems={setSelectedItems}
                    dropItems={setAvailableItems}
                    consolidationCenters={consolidationCenterCombo}
                  />
                </Grid>
              </DndProvider>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}
