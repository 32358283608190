import { AccordionDetails, Grid, MenuItem, Paper, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Accordion } from "../../../components/Accordion/Accordion";
import { AccordionSummary } from "../../../components/Accordion/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ContainerTypesProps } from "../../../types/containerType";
import { Control, Controller, FieldErrors, UseFormRegister } from "react-hook-form";
import { Process } from "../../../types/process";
import { Invoice } from "../../../types/invoice";
import { MobileDatePicker } from "@mui/x-date-pickers";

type ProcessShipmentProps = {
  process: Process,
  register: UseFormRegister<Process>,
  formErrors: FieldErrors<Process>,
  control: Control<Process>,
  containerType: ContainerTypesProps[],
  disabledByInvoiceAllFields: Invoice | undefined,
}

export default function ProcessShipment({
  process,
  register,
  formErrors,
  control,
  containerType,
  disabledByInvoiceAllFields
}: ProcessShipmentProps) {
  return (
    <Accordion
      sx={{
        '&.MuiAccordion-root': {
          border: 0,
          marginBottom: 3,
          marginTop: 7
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography>Shipment</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Paper style={{ marginTop: 45, padding: 27, paddingBottom: 70 }}>
          <Grid container spacing={3}>
            <Grid item xs={12 / 4}>
              <TextField
                label="Flight Number"
                InputLabelProps={{ shrink: true }}
                size="small"
                defaultValue={process?.NUMERO_VOO}
                {...register("NUMERO_VOO")}
                error={!!formErrors.NUMERO_VOO}
                disabled={!!disabledByInvoiceAllFields}
              />
            </Grid>
            <Grid item xs={12 / 4}>
              <TextField
                label="Vessel Name"
                InputLabelProps={{ shrink: true }}
                defaultValue={process?.NOME_NAVIO}
                {...register("NOME_NAVIO")}
                error={!!formErrors.NOME_NAVIO}
                size="small"
                disabled={!!disabledByInvoiceAllFields}
              />
            </Grid>
            <Grid item xs={12 / 4}>
              <TextField
                label="Voyage"
                InputLabelProps={{ shrink: true }}
                size="small"
                defaultValue={process?.CODIGO_VIAGEM_NAVIO}
                error={!!formErrors.CODIGO_VIAGEM_NAVIO}
                {...register("CODIGO_VIAGEM_NAVIO")}
                disabled={!!disabledByInvoiceAllFields}
              />
            </Grid>
            <Grid item xs={12 / 4}>
              <TextField
                select
                label="Container Type"
                fullWidth
                {...register("ID_TIPO_CONTAINER")}
                InputLabelProps={{ shrink: true }}
                size="small"
                type="tel"
                defaultValue={process?.ID_TIPO_CONTAINER}
                error={!!formErrors.ID_TIPO_CONTAINER}
                disabled={!!disabledByInvoiceAllFields}
              >
                {containerType && containerType.map((container) => (
                  <MenuItem value={container.ID_TIPO_CONTEINER}>
                    {container.DESCRICAO}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12 / 4}>
              <TextField
                label="Container Number"
                defaultValue={process?.NUM_CONTEINER}
                {...register("NUM_CONTEINER")}
                InputLabelProps={{ shrink: true }}
                error={!!formErrors.NUM_CONTEINER}
                size="small"
                disabled={!!disabledByInvoiceAllFields}
              />
            </Grid>
            <Grid item xs={12 / 4}>
              <TextField
                label="Demurrage Free Time"
                defaultValue={process?.DSC_FREE_TIME}
                {...register("DSC_FREE_TIME")}
                InputLabelProps={{ shrink: true }}
                size="small"
                error={!!formErrors.DSC_FREE_TIME}
                disabled={!!disabledByInvoiceAllFields}
              />
            </Grid>
            <Grid item xs={12 / 4}>
              <Controller
                name="DATA_SAIDA_CAMINHAO"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <MobileDatePicker
                    label="Truck Scan out Date"
                    disabled={!!disabledByInvoiceAllFields}
                    defaultValue={process?.DATA_SAIDA_CAMINHAO ? dayjs(new Date(process.DATA_SAIDA_CAMINHAO)) : null}
                    {...register("DATA_SAIDA_CAMINHAO")}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                        error: !!formErrors.DATA_SAIDA_CAMINHAO,
                        InputLabelProps: {
                          shrink: true
                        },
                      },
                    }}
                    format="MM/DD/YYYY"
                    onChange={(date: any) => {
                      const formattedDate = date && dayjs(date).isValid() ? dayjs(date).toISOString() : null;
                      onChange(formattedDate);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Paper>
      </AccordionDetails>
    </Accordion>
  )
}