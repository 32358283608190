import { Download } from "@mui/icons-material";
import {
  Button, Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import Search from "../../../components/Search";
import { useList as useListCenter } from "../../../services/centers";
import { useList as useListCompanies } from "../../../services/companies";
import { create, deleteShipment, exportAll, update, useList, createCopy, isInUse, getCenters } from "../../../services/locationBin";
import { getLocal, getToken } from "../../../storage/auth";
import CustomRegimeForm from "./components/Form";
import DataTable from "./components/DataTable";
import { hasClaim } from "../../../storage/userClaims";
import { LocationBinProps } from "../../../types/locationBin";
import Copy from "../../../components/Copy";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import useForms from "../../utils/forms/useForm.hook";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
}

const mapData = (row: LocationBinProps, newCustomRegimes: { ID_CENTRO_DESTINATION: any }) => ({
  DSC_LOCATION: row.DSC_LOCATION,
  ID_CENTRO: newCustomRegimes.ID_CENTRO_DESTINATION,
  ATIVO: row.ATIVO,
} as unknown as LocationBinProps);

const formatData = (data: LocationBinProps) => {
  return {
    ...data,
    ID_CENTRO: data.GC_ID_CENTRO,
  };
}

export default function LocationBin() {

  const [userValidation, setUserValidation] = useState<string>();
  const [token, setToken] = useState<string>();

  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    const getUser = (getLocal("userInfo")) as any;
    setToken(getTokens);
    setUserValidation(JSON.parse(getUser));
  };

  useEffect(() => { getTokenSession() }, []);

  const [params, setParams] = useState<IParams>({ page: 1, limit: 10 } as IParams);
  const { data, meta, isLoading } = useList(params, token);
  const { combo: dataCenterCombo, comboLocationBin } = useListCenter(
    params,
    token,
    true
  );

  const { data: dataCompanies } = useListCompanies(undefined, token);

  const {
    selectedData,
    controlEdit,
    isSaving,
    modalOpen,
    setModalOpen,
    modalCopyOpen,
    setModalCopyOpen,
    openDeleteModal,
    setOpenDeleteModal,
    errors,
    paginationModel,
    readOnly,
    setPaginationModel,

    handleRequest,
    handleEditData,
    handleDeleteDataConfirmation,
    handleDeleteData,
    handleFormSubmit,
    exportData,
    handleCancelEditData,
    onViewData,
    onNew,
    handleCopy,
    handleCancelCopy
  } = useForms({
    actions: {
      create,
      deleteData: deleteShipment,
      exportAll,
      update,
      isInUse,
      createCopy
    },
    configs:{
      findKey: "ID_LOCATION_BIN",
      entityName: "Location Bin",
    },

    data,
    params,
    setParams
  });

  const columns: GridColDef[] = [
    {
      field: "DSC_LOCATION",
      headerName: "Description",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "ATIVO",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      flex: 1,
      renderCell: (params: GridRenderCellParams<Date>) =>
          params.value ? (
              <Chip label="Active" color="primary" />
          ) : (
              <Chip label="Inactive" variant="outlined" />
          ),
    },
  ];

  return (
    <Grid marginTop={4}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <Typography variant="h5">Location Bin</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" marginTop={1}>
        <Grid item xs={2}>
          {!modalOpen ? (
            <Grid item xs={2}>
              <Button
                sx={{
                  display: hasClaim("Location Bin - Maintenance")
                    ? "block"
                    : "none",
                }}
                variant="contained"
                onClick={onNew}
                fullWidth
              >
                New
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {modalOpen ? (
        <Grid container alignItems="center">
          <CustomRegimeForm
            onSubmit={handleFormSubmit}
            shipment={selectedData}
            center={dataCenterCombo}
            companies={dataCompanies}
            onCancel={handleCancelEditData}
            isLoading={isSaving}
            errors={errors}
            controlEdit={controlEdit}
            readOnly={readOnly}
          />
        </Grid>
      ) : null}
      <Search centerPlant={dataCenterCombo} onChange={handleRequest} />
      <Grid
        container
        marginTop={2}
        marginBottom={2}
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Grid item xs={3} justifyContent="center">
          <Button
            style={{ width: "100%", backgroundColor: "#d3d3d3", color: "#000" }}
            onClick={() => {
              setModalCopyOpen(true);
            }}
          >
            Copy
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="text"
            onClick={exportData}
            startIcon={<Download />}
          >
            Export XLSX
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <DataTable
          loading={isLoading}
          data={data}
          center={dataCenterCombo}
          companies={dataCompanies}
          userValidation={userValidation}
          onDelete={handleDeleteDataConfirmation}
          onEdit={(data: string) =>  handleEditData(+data, formatData) }
          onView={(data: string) =>  onViewData(+data, formatData) }
          rowCount={meta.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          paginationModel={paginationModel}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>
      <Dialog
        onClose={handleCancelEditData}
        open={modalCopyOpen}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle>Copy Company - Plant</DialogTitle>
        <DialogContent dividers={true}>
          <Copy
            dataCenterCombo={dataCenterCombo}
            handleCopy={(copyItems, newItems) => handleCopy(copyItems, newItems, mapData)}
            comboRegime={comboLocationBin}
            data={data}
            companies={dataCompanies}
            onCancel={handleCancelCopy}
            isLoading={isSaving}
            errors={errors}

            idKey="locationbinid"
            columns={columns}
            getCenters={getCenters}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Location Bin</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete this Location Bin?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          <Button onClick={handleDeleteData}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
