import { useEffect, useState } from "react";

import { Download } from "@mui/icons-material";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";

import Search from "../../../components/Search";
import { useList as useListCenter } from "../../../services/centers";
import { useList as useListCompanies } from "../../../services/companies";
import { create, createCopy, deleteCustomRegime, exportAll, update, useList, isInUse, getCenters} from "../../../services/customRegime";
import { getLocal, getToken } from "../../../storage/auth";
import { hasClaim } from "../../../storage/userClaims";
import { CustomRegime } from "../../../types/customRegime";
import Copy from "../../../components/Copy";
import CustomRegimeForm from "./components/CustomRegimeForm";
import DataTable from "./components/DataTable";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import useForms from "../../utils/forms/useForm.hook";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
}

const formatData = (data: CustomRegime) => {
  return {
    ...data,
    ID_CENTRO: data.GC_ID_CENTRO,
  };
}

const mapCustomRegime = (row, newCustomRegimes: { ID_CENTRO_DESTINATION: any }) => ({
  DESCRICAO_REGIME: row.DESCRICAO_REGIME,
  CODIGO_SAP: row.CODIGO_SAP,
  ID_CENTRO: newCustomRegimes.ID_CENTRO_DESTINATION,
  ATIVO: row.ATIVO,
});

export default function CustomRegimes() {
  const [userValidation, setUserValidation] = useState<string>();
  const [token, setToken] = useState<string>();

  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    const getUser = (getLocal("userInfo")) as any;
    setToken(getTokens);
    setUserValidation(JSON.parse(getUser));
  };

  useEffect(() => {
    getTokenSession();
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const { data, meta, isLoading } = useList(params, token);

  const { data: dataCompanies } = useListCompanies(undefined, token);

  const {
    selectedData,
    controlEdit,
    isSaving,
    modalOpen,
    modalCopyOpen,
    setModalOpen,
    openDeleteModal,
    setOpenDeleteModal,
    errors,
    readOnly,
    paginationModel,
    setPaginationModel,
    setModalCopyOpen,

    handleRequest,
    handleEditData,
    handleDeleteDataConfirmation,
    handleDeleteData,
    handleFormSubmit,
    exportData,
    handleCancelEditData,
    onViewData,
    onNew,
    handleCancelCopy,
    handleCopy,
  } = useForms({
    actions: {
      create,
      deleteData: deleteCustomRegime,
      exportAll,
      update,
      isInUse,
      createCopy,
    },
    configs:{
      findKey: "ID_REGIME",
      entityName: "Custom Regime",
    },

    data,
    params,
    setParams
  });

  const columns: GridColDef[] = [
    {
      field: "DESCRICAO_REGIME",
      headerName: "Description",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "CODIGO_SAP",
      headerName: "SAP Code",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "ATIVO",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      flex: 1,
      renderCell: (params: GridRenderCellParams<Date>) =>
          params.value ? (
              <Chip label="Active" color="primary" />
          ) : (
              <Chip label="Inactive" variant="outlined" />
          ),
    },
  ];

  return (
    <Grid marginTop={4}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <Typography variant="h5">Custom Regime</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" marginTop={1}>
        <Grid item xs={2}>
          {!modalOpen ? (
            <Grid item xs={2}>
              <Button
                sx={{
                  display: hasClaim("Custom Regime - Maintenance")
                    ? "block"
                    : "none",
                }}
                variant="contained"
                onClick={() => onNew()}
                fullWidth
              >
                New
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {modalOpen ? (
        <Grid container alignItems="center">
          <CustomRegimeForm
            onSubmit={handleFormSubmit}
            customRegime={selectedData}
            companies={dataCompanies}
            onCancel={handleCancelEditData}
            isLoading={isSaving}
            errors={errors}
            controlEdit={controlEdit}
            readOnly={readOnly}
          />
        </Grid>
      ) : null}
      <Search onChange={handleRequest} />
      <Grid
        container
        marginTop={2}
        marginBottom={2}
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Grid item>
          <Button
            variant="text"
            onClick={exportData}
            startIcon={<Download />}
          >
            Export XLSX
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <DataTable
          loading={isLoading}
          data={data}
          companies={dataCompanies}
          userValidation={userValidation}
          onDeleteCustomRegime={handleDeleteDataConfirmation}
          onEditCustomRegime={(data: string) => handleEditData(+data, formatData) }
          onViewCustomRegime={(data: string) =>  onViewData(+data, formatData) }
          rowCount={meta.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);

            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          paginationModel={paginationModel}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Customs Regime</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete this Customs Regime?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          <Button onClick={handleDeleteData}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
