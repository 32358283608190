import Reports from ".";
import { CustomReportProvider } from "./hooks/customReportData.hook";
import { FieldsToHideProvider } from "./hooks/fieldsToHide.hook";
import { TranslationProvider } from "./hooks/translation.hook";

export const reportRoutes = [
  {
    path: "/reports/operational-pipeline",
    element: (
      <TranslationProvider>
        <FieldsToHideProvider>
          <CustomReportProvider ID_RELATORIO={1}>
            <Reports ID_RELATORIO={1} title='Reports: Operational - Pipeline'/>
          </CustomReportProvider>
        </FieldsToHideProvider>
      </TranslationProvider>
    ),
  },
  {
    path: "/reports/operational-discrepancy",
    element: (
      <TranslationProvider>
        <FieldsToHideProvider>
          <CustomReportProvider ID_RELATORIO={2}>
            <Reports ID_RELATORIO={2} title='Reports: Operational - Discrepancy'/>
          </CustomReportProvider>
        </FieldsToHideProvider>
      </TranslationProvider>
    ),
  },
  {
    path: "/reports/operational-pending",
    element: (
      <TranslationProvider>
        <FieldsToHideProvider>
          <CustomReportProvider ID_RELATORIO={3}>
            <Reports ID_RELATORIO={3} title='Reports: Operational - Pending'/>
          </CustomReportProvider>
        </FieldsToHideProvider>
      </TranslationProvider>
    ),
  },
  {
    path: "/reports/operational-missing",
    element: (
      <TranslationProvider>
        <FieldsToHideProvider>
          <CustomReportProvider ID_RELATORIO={4}>
            <Reports ID_RELATORIO={4} title='Reports: Operational - Missing'/>
          </CustomReportProvider>
        </FieldsToHideProvider>
      </TranslationProvider>
    ),
  },
  {
    path: "/reports/operational-receiving-control",
    element: (
      <TranslationProvider>
        <FieldsToHideProvider>
          <CustomReportProvider ID_RELATORIO={5}>
            <Reports ID_RELATORIO={5} title='Reports: Operational - Receiving Control'/>
          </CustomReportProvider>
        </FieldsToHideProvider>
      </TranslationProvider>
    ),
  },
  {
    path: "/reports/customs-audit",
    element: (
      <TranslationProvider>
        <FieldsToHideProvider>
          <CustomReportProvider ID_RELATORIO={6}>
            <Reports ID_RELATORIO={6} title='Reports: Operational - Customs Audit'/>
          </CustomReportProvider>
        </FieldsToHideProvider>
      </TranslationProvider>
    ),
  },
  {
    path: "/reports/operational-missing-entry-form",
    element: (
      <TranslationProvider>
        <FieldsToHideProvider>
          <CustomReportProvider ID_RELATORIO={7}>
            <Reports ID_RELATORIO={7} title='Reports: Operational - Missing Entry Form'/>
          </CustomReportProvider>
        </FieldsToHideProvider>
      </TranslationProvider>
    ),
  },
  {
    path: "/reports/invoice-migo",
    element: (
      <TranslationProvider>
        <FieldsToHideProvider>
          <CustomReportProvider ID_RELATORIO={8}>
            <Reports ID_RELATORIO={8} title='Reports: Operational - Invoice MIGO'/>
          </CustomReportProvider>
        </FieldsToHideProvider>
      </TranslationProvider>
    ),
  },
  {
    path: "/reports/triage-picking-list",
    element: (
      <TranslationProvider>
        <FieldsToHideProvider>
          <CustomReportProvider ID_RELATORIO={10}>
            <Reports ID_RELATORIO={10} title='Reports: Triage - Picking List'/>
          </CustomReportProvider>
        </FieldsToHideProvider>
      </TranslationProvider>
    ),
  },
  {
    path: "/reports/general",
    element: (
      <TranslationProvider>
        <FieldsToHideProvider>
          <CustomReportProvider ID_RELATORIO={11}>
            <Reports ID_RELATORIO={11} title='Reports: General'/>
          </CustomReportProvider>
        </FieldsToHideProvider>
      </TranslationProvider>
    ),
  },
];
