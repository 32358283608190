import { useEffect, useState } from "react";

import { Download } from "@mui/icons-material";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, } from "@mui/material";
import Grid from '@mui/material/Grid2';

import Search from "../../../components/Search";
import { create, createCopy, deleteFreightForwarderRule, exportAll, useList, getCenters } from "../../../services/freightForwarderRule";
import { getLocal, getToken } from "../../../storage/auth";
import { hasClaim } from "../../../storage/userClaims";
import Copy from "../../../components/Copy";
import DataTable from "./components/DataTable";
import FreightForwarderRuleForm from "./components/FreightForwarderRuleForm";
import { FreightForwarderRule } from "../../../types/freightForwarderRule";
import {GridActionsCellItem, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {useList as useListCenter} from "../../../services/centers";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useForms from "../../utils/forms/useForm.hook";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
}

const mapCargoTreatment = (row, newCustomRegimes: { ID_CENTRO_DESTINATION: any }) => ({
  DESCRICAO_REGIME: row.DESCRICAO_REGIME,
  CODIGO_SAP: row.CODIGO_SAP,
  ID_PAIS_ORIGEM: row.GPO_ID_PAIS,
  ID_PAIS_DESTINO: row.GPD_ID_PAIS,
  ID_PRIORIDADE: row.GPRI_ID_PRIORIDADE,
  ID_PARCEIRO_AGENTE_CARGA: row.GPAR_ID_PARCEIRO,
  ID_CENTRO: newCustomRegimes.ID_CENTRO_DESTINATION,
  ID_PARCEIRO_BROKER: row.GPARB_ID_PARCEIRO,
  DG: row.DG,
  OVERSIZE: row.OVERSIZE,
  ATIVO: row.ATIVO,
} as unknown as FreightForwarderRule);

export default function FreightForwarderRules() {
  const [userValidation, setUserValidation] = useState<string>();
  const [token, setToken] = useState<string>();

  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    const getUser = (getLocal("userInfo")) as any;
    setToken(getTokens);
    setUserValidation(JSON.parse(getUser));
  };

  useEffect(() => {
    getTokenSession();
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const { data, meta, isLoading, center, country, serviceLevel, freightForwarder, broker } = useList(params, token);
  const { combo: dataCenterCombo, freightForwarderRule } = useListCenter(
      params,
      token,
      true
  );

  const {
    selectedData,
    isSaving,
    modalOpen,
    setModalOpen,
    modalCopyOpen,
    setModalCopyOpen,
    openDeleteModal,
    setOpenDeleteModal,
    errors,
    paginationModel,
    readOnly,
    setPaginationModel,

    handleRequest,
    handleDeleteDataConfirmation,
    handleDeleteData,
    handleFormSubmit,
    exportData,
    handleCancelEditData,
    onViewData,
    onNew,
    handleCopy,
    handleCancelCopy
  } = useForms({
    actions: {
      create,
      deleteData: deleteFreightForwarderRule,
      exportAll,
      createCopy
    },
    configs:{
      findKey: "ID_REGRA_AGENTE_CARGA",
      entityName: "Rules Freight Forwarder",
    },

    data,
    params,
    setParams
  });

  const renderMenus = (row: any) => {
    return [
      <GridActionsCellItem
          key={`view-${row.ID_REGRA_AGENTE_CARGA}`}
          icon={<VisibilityIcon />}
          label="View"
          onClick={() => onViewData(row.ID_REGRA_AGENTE_CARGA)}
          color="inherit"
          data-testid="view-button"
      />,
      <GridActionsCellItem
          sx={{
            display: hasClaim("Rules for Freight Forwarder - Maintenance") ? "block" : "none",
          }}
          key={`delete-${row.ID_REGRA_AGENTE_CARGA}`}
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDeleteDataConfirmation(row.ID_REGRA_AGENTE_CARGA)}
          color="inherit"
          data-testid="delete-button"
      />,
    ];
  };

  const columns: GridColDef[] = [
    {
      field: "GC_NOME_CENTRO",
      headerName: "Company - Plant",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return `${params.row.GE_SIGLA_EMPRESA} - ${params.row.GC_DSC_SIGLA} - ${params.row.GC_NOME_CENTRO}`;
      },
      minWidth: 300,
    },
    {
      field: "GPO_SIGLA_PAIS",
      headerName: "Origin Country",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return `${params.row.GPO_SIGLA_PAIS} - ${params.row.GPO_NOME_PAIS}`;
      },
    },
    {
      field: "GPD_SIGLA_PAIS",
      headerName: "Destination Country",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return `${params.row.GPD_SIGLA_PAIS} - ${params.row.GPD_NOME_PAIS}`;
      },
    },
    {
      field: "GPRI_DSC_PRIORIDADE",
      headerName: "Service Level",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "DG",
      headerClassName: "super-app-theme--header",
      headerName: "DG",
      width: 85,
      renderCell: (params: GridRenderCellParams<Date>) =>
          params.value ? (
              <Chip label="Yes" color="primary" />
          ) : (
              <Chip label="No" variant="outlined" />
          ),
    },
    {
      field: "OVERSIZE",
      headerClassName: "super-app-theme--header",
      headerName: "OVERSIZE",
      width: 85,
      renderCell: (params: GridRenderCellParams<Date>) =>
          params.value ? (
              <Chip label="Yes" color="primary" />
          ) : (
              <Chip label="No" variant="outlined" />
          ),
    },
    {
      field: "GPAR_NOME_PARCEIRO",
      headerName: "Freight Forwarder",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 80,
      getActions: ({ row }) => renderMenus(row),
    },
  ];

  return (
      <Grid marginTop={4}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h5">Rules for Freight Forwarder</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" marginTop={1}>
          <Grid item xs={2}>
            {!modalOpen ? (
                <Grid item xs={2}>
                  <Button
                      sx={{
                        display: hasClaim("Custom Regime - Maintenance")
                            ? "block"
                            : "none",
                      }}
                      variant="contained"
                      onClick={onNew}
                      fullWidth
                  >
                    New
                  </Button>
                </Grid>
            ) : null}
          </Grid>
        </Grid>
        {modalOpen ? (
            <Grid container alignItems="center">
              <FreightForwarderRuleForm
                  onSubmit={handleFormSubmit}
                  center={center}
                  country={country}
                  serviceLevel={serviceLevel}
                  freightForwarder={freightForwarder}
                  onCancel={handleCancelEditData}
                  isLoading={isSaving}
                  errors={errors}
                  broker={broker}
                  readOnly={readOnly}
                  dataEdit={selectedData}
              />
            </Grid>
        ) : null}
        <Search
            disableStatus
            dg
            onChange={handleRequest}
            centerPlant={center}
            serviceLevelCombo={serviceLevel}
            originCountryCombo={country}
            destinationCountryCombo={country}
            partnerFreightForwarderCombo={freightForwarder}
            isGeneral={false}
        />
        <Grid
            container
            marginTop={2}
            marginBottom={2}
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
        >
          <Grid item xs={3} justifyContent="center">
            <Button
                style={{ width: "100%", backgroundColor: "#d3d3d3", color: "#000" }}
                onClick={() => {
                  setModalCopyOpen(true);
                  setParams({
                    ...params,
                    limit: 200,
                  });
                }}
            >
              Copy
            </Button>
          </Grid>
          <Grid item>
            <Button
                variant="text"
                onClick={exportData}
                startIcon={<Download />}
            >
              Export XLSX
            </Button>
          </Grid>
        </Grid>
        <Grid>
          <DataTable
              loading={isLoading}
              data={data}
              columns={columns}
              userValidation={userValidation}
              rowCount={meta.total || 0}
              onPaginationModelChange={(e: any) => {
                setPaginationModel(e);

                handleRequest({ page: e.page + 1, limit: e.pageSize });
              }}
              pagination
              initialState={{
                pagination: { paginationModel: { pageSize: params.limit } },
              }}
              paginationModel={paginationModel}
              pageSizeOptions={[10, 25, 50, 100]}
              paginationMode="server"
          />
        </Grid>
        <Dialog
            onClose={handleCancelEditData}
            open={modalCopyOpen}
            maxWidth="md"
            fullWidth={true}
        >
          <DialogContent dividers={true}>
            <Copy
                dataCenterCombo={dataCenterCombo}
                handleCopy={(copyItems, newItems) => handleCopy(copyItems, newItems, mapCargoTreatment)}
                comboRegime={freightForwarderRule}
                data={data}
                onCancel={handleCancelCopy}
                isLoading={isSaving}
                errors={errors}

                idKey="freightforwarderruleid"
                columns={columns}
                getCenters={getCenters}
            />
          </DialogContent>
        </Dialog>
        <Dialog
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Rules for Freight Forwarder</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure that you want to delete this Rules for Freight Forwarder?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
            <Button onClick={handleDeleteData}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </Grid>
  );
}
