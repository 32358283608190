import {
  Autocomplete,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { ComboBoxType } from "../../../../../types/general";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { findCenterCombo, findInterfaceCombo } from "../../../../../services/logInterface";
import dayjs from "dayjs";

interface SearchLogProps {
  setParams: (params: any) => void;
  handleSearch: (params?: any) => void;
}

let ediFileScheme = yup.object({
  ID_CENTRO: yup.string().notRequired(),
  ID_INTEGRACAO_SAP: yup.string().notRequired(),
  DATA_OCORRENCIA_INICIO: yup.string().notRequired(),
  DATA_OCORRENCIA_FIM: yup.string().notRequired(),
});

export default function SearchLog({
  setParams,
  handleSearch,
}: SearchLogProps) {

  const [centerCombo, setCenterCombo] = useState<ComboBoxType[]>([]);
  const [interfaceCombo, setInterfaceCombo] = useState<ComboBoxType[]>([]);

  const {
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(ediFileScheme),
  });

  const handleFormSearch = (searchParams: any) => {
    setParams((prevState: any) => ({
      ...prevState,
      page: 1,
      ID_CENTRO: searchParams.ID_CENTRO,
      ID_INTEGRACAO_SAP: searchParams.ID_INTEGRACAO_SAP,
      DATA_OCORRENCIA_INICIO: searchParams.DATA_OCORRENCIA_INICIO ? searchParams.DATA_OCORRENCIA_INICIO.split('T')[0] : undefined,
      DATA_OCORRENCIA_FIM: searchParams.DATA_OCORRENCIA_FIM ? searchParams.DATA_OCORRENCIA_FIM.split('T')[0] + ' 23:59:59.999' : undefined,
    }));
  }

    useEffect(() => {
        const fetchData = async () => {
            const findCenter = await findCenterCombo();
            const findInterface = await findInterfaceCombo();

            setCenterCombo(findCenter);
            setInterfaceCombo(findInterface);
        };

        fetchData();
    }, []);


    return (
    <form
      onSubmit={handleSubmit(handleFormSearch)}
      style={{ width: "100%" }}
      data-testid="edi-file-form"
    >
        <Grid container spacing={2} marginBottom={2} marginTop={4} rowGap={1}>
            <Grid item xs={6} md={3}>
                <TextField
                    data-testid="search-searchGeneral-input"
                    fullWidth
                    type="text"
                    label="General Search"
                    size="small"
                    onChange={(e) => {
                        setParams((prevState) => ({
                            ...prevState,
                            searchGeneral: e.target.value.trim(),
                        }));
                    }}
                    onKeyDown={(event: React.KeyboardEvent) => {
                        if (event.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                />
            </Grid>

            <Grid item xs={6} md={3}>
                <Controller
                    control={control}
                    name="ID_CENTRO"
                    render={({ field: { value, onChange, ...field } }) => (
                        <Autocomplete
                            options={centerCombo || []}
                            getOptionLabel={(option: ComboBoxType) => `${option.description}`}
                            value={centerCombo?.find((item: any) => item.id === value) || null}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    {...field}
                                    label="Company/Plant"
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                            onChange={(_, data: any) => onChange(data?.id)}
                        />
                    )}
                />
            </Grid>

            <Grid item xs={6} md={3}>
                <Controller
                    control={control}
                    name="ID_INTEGRACAO_SAP"
                    render={({ field: { value, onChange, ...field } }) => (
                        <Autocomplete
                            options={interfaceCombo || []}
                            getOptionLabel={(option: ComboBoxType) => `${option.description}`}
                            value={interfaceCombo?.find((item: any) => item.id === value) || null}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    {...field}
                                    label="Interface Name"
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                            onChange={(_, data: any) => onChange(data?.id)}
                        />
                    )}
                />
            </Grid>

            {/* Ajuste para os DatePickers ficarem lado a lado */}
            <Grid item xs={6} md={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                        name="DATA_OCORRENCIA_INICIO"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <DatePicker
                                label="Initial Date"
                                value={value ? dayjs(value) : null}
                                onChange={(date) => onChange(dayjs(date)?.isValid() ? dayjs(date).toISOString() : null)}
                                slotProps={{
                                    textField: {
                                        variant: "outlined",
                                        fullWidth: true,
                                        size: "small",
                                    },
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={6} md={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                        name="DATA_OCORRENCIA_FIM"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <DatePicker
                                label="Final Date"
                                value={value ? dayjs(value) : null}
                                onChange={(date) => onChange(dayjs(date)?.isValid() ? dayjs(date).toISOString() : null)}
                                slotProps={{
                                    textField: {
                                        variant: "outlined",
                                        fullWidth: true,
                                        size: "small",
                                    },
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={2}>
                <Button
                    startIcon={<SearchIcon />}
                    variant="outlined"
                    data-testid="submit-button"
                    type="submit"
                    fullWidth
                >
                    Search
                </Button>
            </Grid>
        </Grid>

    </form>
  );
}
