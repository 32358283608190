import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Alert, Autocomplete, Button, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import * as yup from "yup";
import { isAxiosError } from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { Save } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";

import { getBuyerGroupCombo } from "../../../../../../services/buyerGroup";
import { ComboBoxType } from "../../../../../../types/general";
import { updateTriage } from "../../../../../../services/triage";
import { Triage, TriageStatus } from "../../types/triage";
import { findBuyerGroupByNumeroPO } from "../../../../../../services/po";
import { getUserClaim } from "../../../../../../storage/userClaims";
import LoadingOverlay from "../../../../../../components/LoadingOverlay";

const emailSenderSchema = yup.object({
  PURCHASE_GROUP: yup.number().nullable().optional().when('SEND_EMAIL_TO_PURCHASE', {
    is: true,
    then: (schema) => schema.required('Purchase Group is required'),
  }),
  NUM_PO: yup.string().nullable().optional().when('SEND_EMAIL_TO_PURCHASE', {
    is: true,
    then: (schema) => schema.required('PO Number is required'),
  }),
  ID_TIPO_INVOICE: yup.string().nullable().optional().when('$triageInvoices', {
    is: true,
    then: (schema) => schema.required('Triage Type is required'),
  }),
  SEND_EMAIL_TO_PURCHASE: yup.boolean()
});

type FormProps = {
  triageStatus: string;
  totalFiles: number;
  triage: Triage;
  onUpdate: () => void;
}

export default function Form({
  triageStatus,
  totalFiles,
  triage,
  onUpdate,
}: FormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    control,
    watch,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      PURCHASE_GROUP: null,
      NUM_PO: null,
      ID_TIPO_INVOICE: null,
      SEND_EMAIL_TO_PURCHASE: false
    },
    resolver: yupResolver(emailSenderSchema),
    context: { triageInvoices: !!triage?.triageInvoices }
  });

  const navigate = useNavigate();
  const userClaims = getUserClaim();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [buyerGroupCombo, setBuyerGroupCombo] = useState<ComboBoxType[]>([]);
  const [triageTypes] = useState([
    { 
      id: 'Triage - Domestic', 
      description: 'Triage - Domestic'
    },
    {
      id: 'Triage - International',
      description: 'Triage - International'
    }
  ]);

  useEffect(() => {
    reset({
      PURCHASE_GROUP: triage?.ID_GRUPO_COMPRADOR,
      NUM_PO: triage?.NUM_PO,
      ID_TIPO_INVOICE: triage?.ID_TIPO_INVOICE,
      SEND_EMAIL_TO_PURCHASE: false
    });
  }, [triage, reset]);
  
  useEffect(() => {
    if (triage?.ID_TRIAGEM) {
      handleLoadBuyerGroup(triage?.ID_TRIAGEM);
    }
  }, [triage?.ID_TRIAGEM, triage?.NUM_PO]);

  useEffect(() => {
    if (buyerGroupCombo?.length && triage.NUM_PO && !triage?.ID_GRUPO_COMPRADOR) {
      console.log('tem grupo comprador')
      onLoadBuyerGroup(triage.NUM_PO);
    }
  }, [buyerGroupCombo, triage?.ID_GRUPO_COMPRADOR, !triage?.ID_GRUPO_COMPRADOR]);

  const areFieldsEnabled = useMemo(() => {
    if ([
      TriageStatus.UNDER_ANALYSIS,
      TriageStatus.MISSING_INVOICE,
      TriageStatus.UNDER_LOGISTICS_ANALYSIS,
      TriageStatus.PENDING
    ].includes(triageStatus as TriageStatus)) {
      return userClaims.some((claim) => ["Triage - Analysis"].includes(claim));
    }

    return false;
  }, [userClaims, triageStatus]);

  const isButtonSaveEnabled = useMemo(() => {
    if (triageStatus === TriageStatus.MISSING_INVOICE) {
      return userClaims.some((claim) => ["Triage - Analysis", "Triage - Buyer"].includes(claim));
    }

    if ([
      TriageStatus.WAITING_ATTACHMENTS,
      TriageStatus.UNDER_ANALYSIS,
      TriageStatus.UNDER_LOGISTICS_ANALYSIS,
      TriageStatus.PENDING
    ].includes(triageStatus as TriageStatus)) {
      return userClaims.some((claim) => ["Triage - Analysis", "Triage - Register"].includes(claim));
    }

    return false;
  }, [userClaims, triageStatus]);

  const alertErros = useMemo(() => {
    const errorMessages = Object.keys(formErrors).map((key: string) => {
      return formErrors[key as keyof typeof formErrors]?.message;
    });

    return [...errorMessages];
  }, [formErrors]);

  const onLoadBuyerGroup = async (NUM_PO: string | number | undefined) => {
    if (!NUM_PO || !triage?.ID_CENTRO) return;

    setIsLoading(true);
    try {
      const ID_GRUPO_COMPR = await findBuyerGroupByNumeroPO(NUM_PO, triage?.ID_CENTRO);

      const foundPurchaseGroup = buyerGroupCombo.find((combo) => combo.id === ID_GRUPO_COMPR);

      if (foundPurchaseGroup?.id) {
        setValue('PURCHASE_GROUP', foundPurchaseGroup.id as number || null);
      }
    } catch (error) {
      enqueueSnackbar('Could not find purchage group by po', { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  }


  const handleSend = async () => {
    const purchaseGroupId = watch("PURCHASE_GROUP");
    const foundPurchaseGroup = buyerGroupCombo.find((combo) => combo.id === purchaseGroupId);

    const body = {
      ...triage,
      PURCHASE_GROUP: foundPurchaseGroup?.id || null,
      NUM_PO: watch("NUM_PO") || null,
      ID_TIPO_INVOICE: watch("ID_TIPO_INVOICE") || null,
      SEND_EMAIL_TO_PURCHASE: watch("SEND_EMAIL_TO_PURCHASE")
    }

    setIsLoading(true);
    try {
      await updateTriage(body);
      onUpdate();
      const url = `/goods-triage/`;
      return navigate(url);
    } catch (error) {
      if (isAxiosError(error)) {
        enqueueSnackbar(`${error?.response?.data?.message}`, { variant: "error" });
      } else {
        enqueueSnackbar('Could not save triage', { variant: 'error' });
      }
    } finally {
      setIsLoading(false);
    }
  }

  const handleLoadBuyerGroup = async (ID_TRIAGEM: number) => {
    const response = await getBuyerGroupCombo({ ID_TRIAGEM });
    setBuyerGroupCombo(response);
  }

  return (
    <form
      onSubmit={handleSubmit(handleSend)}
    >
      <LoadingOverlay isLoading={isLoading} />
      {alertErros.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}

      <Grid marginBottom={3} container spacing={2}>
        <Grid item xs={4}>
          <Controller
            defaultValue=""
            name="NUM_PO"
            control={control}
            render={() => (
              <TextField
                data-testid="description-input"
                type="text"
                fullWidth
                label="PO"
                variant="outlined"
                size="small"
                {...register("NUM_PO")}
                error={!!formErrors.NUM_PO}
                disabled={!areFieldsEnabled}
                InputLabelProps={{ shrink: true }}
                onBlur={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  const fields = watch();

                  onLoadBuyerGroup(fields.NUM_PO);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={8}>
          <Controller
            control={control}
            defaultValue={null}
            name="PURCHASE_GROUP"
            render={({ field: { value, ref, onChange, ...field } }) => (
              <>
                {
                  buyerGroupCombo && <Autocomplete
                    options={buyerGroupCombo}
                    getOptionLabel={(option) => option.description}
                    getOptionKey={(option) => option.id}
                    value={
                      buyerGroupCombo?.findLast(
                        (item: ComboBoxType) => item?.id === value
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...field}
                        InputLabelProps={{ shrink: true }}
                        label="Purchaser"
                        size="small"
                        inputRef={ref}
                        error={!!formErrors.PURCHASE_GROUP}
                        variant="outlined"
                      />
                    )}
                    onChange={(_: any, data: any) => {
                      const id = data?.id || null;
                      onChange(id)
                    }}
                    disabled={!areFieldsEnabled}
                  />
                }
              </>
            )}
          />
        </Grid>
        <Grid item xs={8}>
          <Controller
            control={control}
            defaultValue=""
            name="ID_TIPO_INVOICE"
            render={({ field: { value, ref, onChange, ...field } }) => (
              <>
                {
                  triageTypes && <Autocomplete
                    options={triageTypes}
                    getOptionLabel={(option) => option.description}
                    value={
                      triageTypes?.findLast(
                        (item: ComboBoxType) => item?.description === value
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...field}
                        InputLabelProps={{ shrink: true }}
                        label="Triage Type"
                        size="small"
                        inputRef={ref}
                        error={!!formErrors.ID_TIPO_INVOICE}
                        variant="outlined"
                        {...register("ID_TIPO_INVOICE")}
                      />
                    )}
                    onChange={(_: any, data: any) => {
                      onChange(data?.description || null);
                    }}
                    disabled={!areFieldsEnabled}
                  />
                }
              </>
            )}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={9} container justifyContent="flex-start">
          <Controller
            defaultValue={false}
            name="SEND_EMAIL_TO_PURCHASE"
            control={control}
            render={({ field: { value } }) => (
              <FormControlLabel
                value={!!value}
                control={
                  <Checkbox
                    checked={!!value}
                    {...register("SEND_EMAIL_TO_PURCHASE")}
                    inputProps={{ "aria-label": "controlled" }}
                    disabled={!areFieldsEnabled}
                  />
                }
                label="Send E-mail to Purchaser"
                labelPlacement="end"
              />
            )}
          />
        </Grid>

        <Grid item xs={3} container justifyContent="flex-end">
          <Button
            variant="outlined"
            type="submit"
            style={{ borderColor: !isButtonSaveEnabled ? "#6C7A64CC" : "#26890D", color: !isButtonSaveEnabled ? "#6C7A64CC" : "#26890D" }}
            disabled={!isButtonSaveEnabled}
          >
            <Save />
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}