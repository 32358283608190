import { useEffect, useState } from "react";
import { List } from "../../types/resquest";
import { api, createApiInstance } from "../../lib/api";
import { getToken } from "../../storage/auth";
import { ComboBoxType } from "../../types/general";

interface IList {
  ID_CENTRO: undefined;
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
  status?: boolean;
  ID_RELATORIO_CUSTOM?: number;
}

const entity = "reports";

export const useList = (
  params: IList,
):
  | Promise<{ data: Array<any>; meta: List; isLoading: boolean }> => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Array<any>>([]);
  const [dataRaw, setDataRaw] = useState<Array<any>>([]);
  const [reportStructure, setReportStructure] = useState<Array<any>>([]);
  const [meta, setMeta] = useState<List>({} as List);
  const [currentPageLocal, setCurrentPageLocal] = useState(1);
  const [center, setCenter] = useState<number | undefined>();
  const [report, setReport] = useState<number | undefined>();
  const [count, setCount] = useState<number>(1);

  useEffect(() => {
    if(params.ID_RELATORIO_CUSTOM) {
      
      (async function () {
        setIsLoading(true);

        const paginateData = (data, page, itemsPerPage) => {
          const start = (page - 1) * itemsPerPage;
          const end = start + itemsPerPage;
          return data.slice(start, end);
        }

        const isNew = () => {
          return params.ID_RELATORIO_CUSTOM !== report
            || params?.ID_CENTRO !== center
            || params?.count !== count
        }

        try {
          if(
              ((dataRaw.length !== meta.total)
                  && (dataRaw.length < (params.page * params.limit)))
              || isNew()
          ) {
            const response = await api.post(`/reports/dynamicReport`, { ...params, page: isNew() ? 1 : currentPageLocal, limit: 100 });
            let { total, data, currentPage, totalPages, limit, reportStructure: reportStructureTemp } = response.data;

            setReportStructure(reportStructureTemp);

            let dataRawTemp;
            if(isNew()) {
              dataRawTemp = data;
            } else {
              dataRawTemp = [ ...dataRaw, ...data ]
            }
            setDataRaw(dataRawTemp);

            setMeta((prevState) => ({
              ...prevState,
              total,
              totalPages,
              limit,
              currentPage,
            }));
            setCurrentPageLocal(isNew() ? 2 : currentPageLocal + 1)

            const paginatedData = paginateData(dataRawTemp, params.page, params.limit);
            setData(paginatedData);

          } else {
            const paginatedData = paginateData(dataRaw, params.page, params.limit);
            setData(paginatedData);
          }
          setCount(params?.count)
          setCenter(params?.ID_CENTRO);
          setReport(params.ID_RELATORIO_CUSTOM);
          setIsLoading(false)
        } catch (error) {
            setIsLoading(false);
        }

      })();
    }
  }, [params]);

  return { data, dataRaw, meta, isLoading, reportStructure, setIsLoading };
};

export const findCenterCombo = async (): Promise<ComboBoxType[]> => {
  const token = await getToken();
  const { data } = await api.get(`/centers/getCentersWithCompaniesCombo`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const findCustomReport = async (
  ID_RELATORIO: number
): Promise<ComboBoxType[]> => {
  const token = await getToken();
  const { data } = await api.get(`/customReports/getCombo?ID_RELATORIO=${ID_RELATORIO}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const saveCustomReport = async (customReport: any) => {
  const token = await getToken();
  const { data } = await api.post(`${entity}/dynamicReport/save`, customReport, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
}

export const deleteCustomReport = async (id: number | string) => {
  const token = await getToken();
  const { data } = await api.delete(`/customReports/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
}

export const findFields = async () => {
  const token = await getToken();
  const { data } = await api.get('/reports/dynamicReport/fields', {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
}

export const getReportCategory = async (ID_RELATORIO) => {
  const token = await getToken();
  const { data } = await api.get(`/reportCategories/getReportCategory?ID_RELATORIO=${ID_RELATORIO}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
}

export const findCustomReportStructure = async (id) => {
  const token = await getToken();
  const { data } = await api.get(`/customReports/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
}

export const findComboBox = async (route: string, params = {}) => {
  const token = await getToken();
  const { data } = await api.get(route, {
    params,
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
}

export const findMfir = async () => {
  const token = await getToken();
  const { data } = await api.get('suppliers/mfir', {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
}

export const exportAll = async (body: any) => {
  const token = await getToken();
  return await api.post(`/reports/dynamicReport/export`, body, {
    headers: { authorization: `Bearer ${token}` },
    responseType: 'blob',
    timeout: 60 * 6 * 1000
  });
};
