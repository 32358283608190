import { yupResolver } from "@hookform/resolvers/yup";
import {
    Alert,
    Autocomplete,
    Button,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import {useEffect, useState} from "react";
import { api } from "../../../../../lib/api";

import { ComboBoxType } from "../../../../../types/general";
import { FreightForwarderRule } from "../../../../../types/freightForwarderRule";

let freightForwarderScheme = yup.object({
    ID_CENTRO: yup.number().required(),
    ID_PAIS_ORIGEM: yup.number().required(),
    ID_PAIS_DESTINO: yup.number().required(),
    DG: yup.boolean(),
    OVERSIZE: yup.boolean(),
    ID_PRIORIDADE: yup.number().required(),
    ID_PARCEIRO_AGENTE_CARGA: yup.number().required(),
    ID_PARCEIRO_BROKER: yup.number().required(),
});

interface FreightForwarderRuleFormProps {
    onSubmit: (freightForwarder: FreightForwarderRule) => void;
    onCancel: () => void;
    isLoading?: boolean;
    errors?: string[];
    center: ComboBoxType[];
    country: ComboBoxType[];
    serviceLevel: ComboBoxType[];
    freightForwarder: ComboBoxType[];
    broker: ComboBoxType[];
    readOnly?: boolean;
    dataEdit?: any;
}

export default function FreightForwarderRuleForm({
        onSubmit,
        onCancel,
        isLoading = false,
        errors = [],
        center,
        country,
        serviceLevel,
        freightForwarder,
        broker,
        readOnly = false,
        dataEdit,
    }: FreightForwarderRuleFormProps) {
    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
        control,
        reset,
        watch,
        setValue,
    } = useForm({
        resolver: yupResolver(freightForwarderScheme),
        defaultValues: {
            ID_CENTRO: dataEdit?.GC_ID_CENTRO || undefined,
            ID_PAIS_ORIGEM: dataEdit?.GPO_ID_PAIS || undefined,
            ID_PAIS_DESTINO: dataEdit?.GPD_ID_PAIS || undefined,
            DG: dataEdit?.DG || false,
            OVERSIZE: dataEdit?.OVERSIZE || false,
            ID_PRIORIDADE: dataEdit?.GPRI_ID_PRIORIDADE || undefined,
            ID_PARCEIRO_AGENTE_CARGA: dataEdit?.GPAR_ID_PARCEIRO || dataEdit?.GPAR_ID_AGENTE_CARGA ||  undefined,
            ID_PARCEIRO_BROKER: dataEdit?.GPARB_ID_PARCEIRO || undefined,
        },
    });

    const [brokers, setBrokers] = useState(broker);
    const [freightForwaderData, setfreightForwadersData] = useState(freightForwarder);

    const selectedCentro = watch("ID_CENTRO");

    useEffect(() => {
        if (selectedCentro) {
            fetchBrokers(selectedCentro);
            fetchFreightForwarder(selectedCentro);
        }
    }, [selectedCentro]);

    const fetchFreightForwarder = async (centroId: number) => {
        api
            .get("/partners/getCombo?partnerType=FREIGHT FORWARDER&ID_CENTRO=" + centroId)
            .then((response) => {
                const { data } = response;
                setValue('ID_PARCEIRO_AGENTE_CARGA', undefined);
                setfreightForwadersData(data);
            });
    }

    const fetchBrokers = async (centroId: number) => {
        api
            .get("/partners/getCombo?partnerType=BROKER&ID_CENTRO=" + centroId)
            .then((response) => {
                const { data } = response;
                setBrokers(data);
            });
        setValue('ID_PARCEIRO_BROKER', undefined);
    };

    useEffect(() => {
        reset({
            ID_CENTRO: dataEdit?.GC_ID_CENTRO || undefined,
            ID_PAIS_ORIGEM: dataEdit?.GPO_ID_PAIS || undefined,
            ID_PAIS_DESTINO: dataEdit?.GPD_ID_PAIS || undefined,
            DG: dataEdit?.DG || false,
            OVERSIZE: dataEdit?.OVERSIZE || false,
            ID_PRIORIDADE: dataEdit?.GPRI_ID_PRIORIDADE || undefined,
            ID_PARCEIRO_AGENTE_CARGA: dataEdit?.GPAR_ID_PARCEIRO || dataEdit?.GPAR_ID_AGENTE_CARGA || undefined,
            ID_PARCEIRO_BROKER: dataEdit?.GPARB_ID_PARCEIRO || undefined,
        });
    }, [dataEdit, reset]);

    const handleFormSubmit = (freightForwarder: FreightForwarderRule) => {
        onSubmit(freightForwarder);
    };

    const handleCancel = () => {
        reset();
        onCancel();
    };

    return (
        <form
            style={{ width: "100%" }}
            onSubmit={handleSubmit(handleFormSubmit as any)}
        >
            {errors.map((error) => (
                <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
                    {error}
                </Alert>
            ))}
            <Grid
                data-testid="search"
                container
                spacing={2}
                marginTop={1}
                border={1}
                padding={2}
                borderColor={"#7b7b7b"}
                borderRadius={2}
                alignItems="center"
            >
                <Grid container spacing={2} marginBottom={6}>
                    <Grid item xs={6}>
                        <Controller
                            control={control}
                            name="ID_CENTRO"
                            render={({ field: { onChange, value, ref } }) => (
                                <Autocomplete
                                    options={center || []}
                                    getOptionLabel={(option) => option.description}
                                    value={
                                        center?.find((item: any) => item.id === value) || null
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Company - Plant"
                                            size="small"
                                            inputRef={ref}
                                            error={!!formErrors.ID_CENTRO}
                                            variant="outlined"
                                            disabled={readOnly}
                                        />
                                    )}
                                    onChange={(_, data) => onChange(data?.id)}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}></Grid>

                    <Grid item xs={5}>
                        <Controller
                            control={control}
                            name="ID_PAIS_ORIGEM"
                            render={({ field: { onChange, value, ref } }) => (
                                <Autocomplete
                                    options={country || []}
                                    getOptionLabel={(option) => option.description}
                                    value={
                                        country?.find((item: any) => item.id === value) || null
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Origin Country"
                                            size="small"
                                            inputRef={ref}
                                            error={!!formErrors.ID_PAIS_ORIGEM}
                                            variant="outlined"
                                            disabled={readOnly}
                                        />
                                    )}
                                    onChange={(_, data) => onChange(data?.id)}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={5}>
                        <Controller
                            control={control}
                            name="ID_PAIS_DESTINO"
                            render={({ field: { onChange, value, ref } }) => (
                                <Autocomplete
                                    options={country || []}
                                    getOptionLabel={(option) => option.description}
                                    value={
                                        country?.find((item: any) => item.id === value) || null
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Destination Country"
                                            size="small"
                                            inputRef={ref}
                                            error={!!formErrors.ID_PAIS_DESTINO}
                                            variant="outlined"
                                            disabled={readOnly}
                                        />
                                    )}
                                    onChange={(_, data) => onChange(data?.id)}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={5}>
                        <Controller
                            control={control}
                            name="ID_PRIORIDADE"
                            render={({ field: { onChange, value, ref } }) => (
                                <Autocomplete
                                    options={serviceLevel || []}
                                    getOptionLabel={(option) => option.description}
                                    value={
                                        serviceLevel?.find((item: any) => item.id === value) || null
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Service Level"
                                            size="small"
                                            inputRef={ref}
                                            error={!!formErrors.ID_PRIORIDADE}
                                            variant="outlined"
                                            disabled={readOnly}
                                        />
                                    )}
                                    onChange={(_, data) => onChange(data?.id)}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Controller
                            name="DG"
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={!!value}
                                            onChange={(e) => onChange(e.target.checked)}
                                            inputRef={ref}
                                            disabled={readOnly}
                                        />
                                    }
                                    labelPlacement="start"
                                    label="DG"
                                />
                            )}
                        />
                        <Controller
                            name="OVERSIZE"
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={!!value}
                                            onChange={(e) => onChange(e.target.checked)}
                                            inputRef={ref}
                                            disabled={readOnly}
                                        />
                                    }
                                    labelPlacement="start"
                                    label="OVERSIZE"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={5}>
                        <Controller
                            control={control}
                            name="ID_PARCEIRO_AGENTE_CARGA"
                            render={({ field: { onChange, value, ref } }) => (
                                <Autocomplete
                                    options={freightForwaderData || []}
                                    getOptionLabel={(option) => option.description}
                                    value={
                                        freightForwaderData?.find((item: any) => item.id === value) ||
                                        null
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Freight Forwarder"
                                            size="small"
                                            inputRef={ref}
                                            error={!!formErrors.ID_PARCEIRO_AGENTE_CARGA}
                                            variant="outlined"
                                            disabled={readOnly}
                                        />
                                    )}
                                    onChange={(_, data) => onChange(data?.id)}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Controller
                            control={control}
                            name="ID_PARCEIRO_BROKER"
                            render={({ field: { onChange, value, ref } }) => (
                                <Autocomplete
                                    options={brokers || []}
                                    getOptionLabel={(option) => option.description}
                                    value={
                                        brokers?.find((item: any) => item.id === value) || null
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Broker"
                                            size="small"
                                            inputRef={ref}
                                            error={!!formErrors.ID_PARCEIRO_BROKER}
                                            variant="outlined"
                                            disabled={readOnly}
                                        />
                                    )}
                                    onChange={(_, data) => onChange(data?.id)}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid>
                        <Button
                            variant="outlined"
                            onClick={handleCancel}
                            disabled={isLoading}
                            data-testid="cancel-button"
                        >
                            Cancel
                        </Button>
                    </Grid>

                    {
                        !readOnly && (
                            <Grid marginLeft={2}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                >
                                    Confirm
                                </Button>
                            </Grid>
                        )
                    }

                </Grid>
            </Grid>
        </form>
    );
}
