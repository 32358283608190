import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import { AutomaticAuthorizationProps } from "../../types/automaticAuthorization";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
  ID_MODAL_TRANSPORTE?: number,
}

const transportationModeData = {
  "AIR": "AIR",
  "ROAD": "ROAD",
  "OCEAN": "OCEAN",
}

const entity = "automaticAuthorizationRules";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  token?: string,
  isCombo?: boolean
):
  | Promise<{
      data: AutomaticAuthorizationProps[];
      meta: List;
      error: Error;
      isLoading: boolean;
    }>
  | any => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<AutomaticAuthorizationProps[]>([]);
  const [meta, setMeta] = useState<List>({} as List);
  const [error] = useState<Error>({} as Error);

  const render = () => {
    if (token) {
      if (isCombo === false || isCombo === undefined) {
        return "pagination";
      }
    }
  };

  useEffect(() => {
    if (render() === "pagination") {
      (async function () {
        setIsLoading(true);
        const response = await dataTableUtils.findPaginate({
          ...params,
          ID_MODAL: params?.ID_MODAL_TRANSPORTE,
        });
        const {total, data, currentPage, totalPages, limit} = response.data;
        setData(data);
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }));
        setIsLoading(false)
      })();
    }
  }, [isCombo, params, token]);

  return {
    data,
    meta,
    error,
    isLoading,
  };
};

export const findById = async (id: number) => {
  return await api.get(`/${entity}/${id}`);
};

export const update = async (
  id: number,
  center?: AutomaticAuthorizationProps
): Promise<AutomaticAuthorizationProps> => {
  const { data } = await api.patch(`/${entity}/${id}`, center);

  return data;
};

export const isInUse = async (id: number | string): Promise<any> => {
  const { data } = await api.get(`/${entity}/isInUse/${id}`);

  return data;
};

export const deleteCenter = async (id: number): Promise<boolean> => {
  const { data } = await api.delete(`/${entity}/${id}`);

    return data;
};

export const findCustomRegime = async (): Promise<any> => {
  return await api.get(`/customsRegimes/getCombo`);
};

export const findShipmentInstruction = async (id: number): Promise<any> => {
  return await api.get(`/shipmentInstructions/getCombo?ID_CENTRO=${id}`);
};

export const create = async (
  center?: AutomaticAuthorizationProps
): Promise<AutomaticAuthorizationProps> => {
  const { data } = await api.post(`/${entity}`, center);

  return data;
};

export const exportAll = async (params: any) => {
  let xlsxData = [
    { label: "Id", value: "ID_REGRA_AUTORIZACAO_EMBARQUE" },
    { label: "Description Rule", value: "DESCRICAO_REGRA" },
    { label: "Company abbreviation", value: "GE_SIGLA_EMPRESA" },
    { label: "Center abbreviation", value: "GC_DSC_SIGLA" },
    { label: "Center name", value: "GC_NOME_CENTRO" },
    { label: "consolidation Center", value: "GCC_NOME" },
    {
      label: "Transportation Mode",
      value: (row: { ID_MODAL: keyof typeof transportationModeData }) => `${transportationModeData[row.ID_MODAL]}`,
    },
    { label: "Material Type", value: "GTM_DESCRICAO" },
    { label: "Goverment Authority", value: "GOA_DSC_ORGAO_ANUENTE" },
    { label: "Custom Regime", value: "GR_DESCRICAO_REGIME" },
    { label: "Service Level", value: "GP_DSC_PRIORIDADE" },
    { label: "Origin Aiport", value: "GAO_DSC_AEROPORTO" },
    { label: "Primary Airport Destination", value: "GAD_DSC_AEROPORTO" },
    { label: "Alternative Airport Destination", value: "GAD_DSC_AEROPORTO" },
    { label: "Shipment Instruction Id", value: "GIE_ID_INSTRUCAO_EMBARQUE" },
    { label: "Shipment Instruction Description", value: "GIE_DSC_INSTRUCAO" },
    { label: "Freight", value: "ID_FRETE_INCO" },
    {
      label: "Status",
      value: (row: any) => (row.ATIVO ? "Active" : "Inactive"),
    },
  ]
  await dataTableUtils.exportAll({params, xlsxData, filename: "Automatic Authorization"});
};

export const reprocess = async (
  ID_REGRA_AUTORIZACAO_EMBARQUE: AutomaticAuthorizationProps["ID_REGRA_AUTORIZACAO_EMBARQUE"],
): Promise<any> => {
  const { data } = await api.patch(`/${entity}/${ID_REGRA_AUTORIZACAO_EMBARQUE}/reprocess`, );

  return data;
};
