import { Download } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import {
  create,
  deleteCenter,
  exportAll,
  update,
  useList,
  isInUse,
} from "../../../services/automaticAuthorizationRules";
import { useList as useCenter } from "../../../services/centers";
import { useList as useConsolidationCenter } from "../../../services/consolidationCenter";
import { useList as useMaterialType } from "../../../services/materialType";
import { useList as useServiceLevel } from "../../../services/serviceLevel";
import { useList as useAirport } from "../../../services/airport";
import { useList as useCustomRegime } from "../../../services/customRegime";
import { useList as useShipmentInstruction } from "../../../services/shipment";
import { useList as useGovernmentsAuthorities } from "../../../services/governmentsAuthorities";

import { getToken } from "../../../storage/auth";
import { AutomaticAuthorizationProps } from "../../../types/automaticAuthorization";
import CenterForm from "./components/form";
import DataTable from "./components/DataTable";
import Search from "../../../components/Search";
import { hasClaim } from "../../../storage/userClaims";
import useForms from "../../utils/forms/useForm.hook";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
}
export default function AutomaticAuthorization() {
  const [token, setToken] = useState<string>();

  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    setToken(getTokens);
  };

  useEffect(() => {
    getTokenSession();
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const { data, meta, error, isLoading } = useList(params, token);
  const { combo: centerCombo } = useCenter(params, token, true);
  const { combo: consolidationCenterCombo } = useConsolidationCenter(
    params,
    token,
    true
  );
  const { combo: materialTypeCombo } = useMaterialType(params, token, true);
  const { combo: serviceLevelCombo } = useServiceLevel(params, token, true);
  const { combo: airportCombo } = useAirport(params, token, true);
  const { combo: customRegimeCombo } = useCustomRegime(params, token, true);
  const { combo: shipmentInstructionCombo } = useShipmentInstruction(
    params,
    token,
    true
  );
  const { combo: governmentsAuthoritiesCombo } = useGovernmentsAuthorities(
    params,
    token,
    true
  );

  const incotermsFreight = [
    {
      id: "COLLECT",
      description: "COLLECT",
    },
    {
      id: "PREPAID",
      description: "PREPAID",
    },
  ];

  const {
    selectedData,
    controlEdit,
    isSaving,
    modalOpen,
    openDeleteModal,
    setOpenDeleteModal,
    errors,
    paginationModel,
    readOnly,
    setPaginationModel,

    handleRequest,
    handleEditData,
    handleDeleteDataConfirmation,
    handleDeleteData,
    handleFormSubmit,
    exportData,
    handleCancelEditData,
    onViewData,
    onNew,
  } = useForms({
    actions: {
      create,
      deleteData: deleteCenter,
      exportAll,
      update,
      isInUse,
    },
    configs:{
      findKey: "ID_REGRA_AUTORIZACAO_EMBARQUE",
      entityName: "Automatic Authorization Rule",
    },

    data,
    params,
    setParams
  });

  const transportationModeData = [
    {
      value: "AIR",
      label: "AIR",
    },
    {
      value: "ROAD",
      label: "ROAD",
    },
    {
      value: "OCEAN",
      label: "OCEAN",
    },
  ];

  return (
    <div id="centers">
      <Grid marginTop={4}>
        {/*{error.message}*/}
        <Grid container spacing={1} direction="column">
          <Grid item xs={10}>
            <Typography variant="h5">Automatic Authorization Rules</Typography>
          </Grid>
          {!modalOpen ? (
            <Grid item xs={2} marginTop={1}>
              <Button
                sx={{
                  display: hasClaim(
                    "Shipment Authorization - Rules - Maintenance"
                  )
                    ? "block"
                    : "none",
                }}
                variant="contained"
                onClick={() => onNew()}
                data-testid="new-button"
              >
                New
              </Button>
            </Grid>
          ) : null}
        </Grid>
        {modalOpen ? (
          <Grid container alignItems="center">
            <CenterForm
              governmentsAuthoritiesCombo={governmentsAuthoritiesCombo}
              shipmentInstructionCombo={shipmentInstructionCombo}
              customRegimeCombo={customRegimeCombo}
              airportCombo={airportCombo}
              serviceLevelCombo={serviceLevelCombo}
              materialTypeCombo={materialTypeCombo}
              consolidationCenterCombo={consolidationCenterCombo}
              centerCombo={centerCombo}
              onSubmit={handleFormSubmit}
              automaticAuthorization={selectedData}
              onCancel={handleCancelEditData}
              isLoading={isSaving}
              errors={errors}
              controlEdit={controlEdit}
              readOnly={readOnly}
              data-testid="center-form"
            />
          </Grid>
        ) : null}
        <Search
          consolidationCenterCombo={consolidationCenterCombo}
          isIconterms={incotermsFreight}
          centerPlant={centerCombo}
          onChange={handleRequest}
          transportationMode={transportationModeData}
          materialTypeCombo={materialTypeCombo}
          serviceLevelCombo={serviceLevelCombo}
          airportCombo={airportCombo}
          isDestinationAirport
        />
        <Grid container marginTop={4} justifyContent="flex-end">
          <Button
            variant="text"
            onClick={exportData}
            startIcon={<Download />}
          >
            Export XLSX
          </Button>
        </Grid>
        <Grid>
          <DataTable
            loading={isLoading}
            data={data}
            onDelete={handleDeleteDataConfirmation}
            rowCount={meta.total || 0}
            onPaginationModelChange={(e: any) => {
              setPaginationModel(e);
              handleRequest({ page: e.page + 1, limit: e.pageSize });
            }}
            paginationModel={paginationModel}
            onEditCenter={handleEditData}
            onViewCenter={onViewData}
            pagination
            initialState={{
              pagination: { paginationModel: { pageSize: params.limit } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            paginationMode="server"
          />
        </Grid>
        <Dialog
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Delete Automatic Authorization Rule
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure that you want to delete this Automatic Authorization Rule?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
            <Button
                onClick={handleDeleteData}
                data-testid="confirm-delete-button"
            >Confirm</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
}
