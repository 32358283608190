import React, { useEffect, useState } from 'react'
import { Label } from '../../../types/po';
import { deleteLabel, exportAll, useList } from '../../../services/label';
import ConfirmDialog from '../../../components/Dialog/ConfirmDialog';
import CreateLabelData from './components/CreateLabelData';
import { getToken } from '../../../storage/auth';
import { Button, Grid, TextField } from '@mui/material';
import { Download } from '@mui/icons-material';
import DataTable from './components/DataTable';
import { useSnackbar } from 'notistack';

const MaintenanceLabelData: React.FC = () => {
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<Label | undefined>();
  const [token, setToken] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();

  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    try {
      setToken(getTokens);
    } catch (err) {
      // console.log(err)
    }
  };
  useEffect(() => {
    getTokenSession();
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const { data = [], meta = {}, isLoading } = useList(params, token) || {};
  const [controlEdit, setControlEdit] = useState({
    loading: false,
    isInUse: false,
  });
  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
  };

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  
  const handleEditLabel = (label: Label) => {
    setSelectedLabel(label)
  };

  const handleDeleteLabel = async (label: Label) => {
    await setSelectedLabel(label);
    setTimeout(() => setConfirmDeleteDialog(true), 100);
  };

  const exportLabels = async () => {
    try {
      await exportAll(params);
    } catch (err) {}
  };

  return (
    <div>
      <CreateLabelData data={selectedLabel} fetchData={() => setParams({ ...params })} />
      
      <Grid container spacing={2} justifyContent="space-between" marginTop={1} marginBottom={2}>
        <Grid item xs={4}>
          <TextField
            name="search"
            value={params?.keyword || ''}
            data-testid="search-input"
            fullWidth 
            label="Search*"
            placeholder="Type Line for search..."
            variant="outlined"
            size="small"
            onChange={(value: any) => handleRequest({ keyword: value?.target?.value })}
          />
        </Grid>

        <Grid item>
          <Button
            variant="text"
            onClick={exportLabels}
            startIcon={<Download />}
          >
            Export XLSX
          </Button>
        </Grid>
      </Grid>

      <DataTable
        loading={isLoading}
        data={data}
        onDeleteLabel={handleDeleteLabel}
        onEditLabel={handleEditLabel}
        rowCount={meta?.total || 0}
        onPaginationModelChange={(e: any) => {
          setPaginationModel(e);
          handleRequest({ page: e.page + 1, limit: e.pageSize });
        }}
        pagination
        paginationModel={paginationModel}
        initialState={{
          pagination: { paginationModel: { pageSize: params.limit } },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationMode="server" 
      />

      <ConfirmDialog 
        dialogMeta={{ 
          message: `Are you sure that you wanna delete this label?`, 
          open: confirmDeleteDialog, 
          title:'Delete confirmation', 
          callbackOnCancel: () => setConfirmDeleteDialog(false),
          callbackOnConfirm: async () => {
            const response = await deleteLabel(selectedLabel?.label_ID_LABEL!);
            enqueueSnackbar(response?.message, { variant: response?.success ? 'success' : "error" });
            setConfirmDeleteDialog(false);
            setParams({ ...params });
          } 
        }}
      />
    </div>
  );
};

export default MaintenanceLabelData;
