import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert, Autocomplete,
  Button,
  Divider,
  Grid, TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import {Controller, useForm} from "react-hook-form";
import * as yup from "yup";

import { SelectedCenterType, PartnerService } from "../../../../../types/partner";
import CenterForm from "./CenterForm";
import EmailForm from "./EmailForm";
import EmailCopyForm from "./EmailCopyForm";
import { ComboBoxType } from "../../../../../types/general";
import {findConsolidationCenter} from "../../../../../services/partner";

let dataScheme = yup.object({
  ID_PARCEIRO: yup.number(),
  NOME_PARCEIRO: yup.string().max(50).required(),
  NUM_MFIR: yup.string().max(10).required(),
  ID_TIPO_PARCEIRO: yup.string().required(),
  ID_PAIS: yup.number().required(),
  ID_CENTRO_CONSOLIDADOR: yup.number().required(),
  ID_AGENTE_CARGA: yup.number().required(),
  CODIGO_EDI: yup.string().max(6).required(),
  partner_centers: yup.array()
    .of(
      yup.object().shape({
        ID_CENTRO: yup.number().required(),
        ATIVO: yup.boolean().required(),
        emails: yup.array().of(
          yup.object().shape({
            DSC_EMAIL: yup.string().max(50).required(),
            ID_TIPO_DESTINO_EMAIL: yup.string().max(15).required(),
          })
        ).min(1).required(),
      })
    ).min(1).required(),
});

interface PartnerFormProps {
  readOnly: boolean;
  entityInUse: boolean;
  partner: PartnerService;
  partnerId: number;
  onSubmit: (partner: PartnerService) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  country: ComboBoxType[],
  center: ComboBoxType[],
  freightForwarder: ComboBoxType[],
}

const PartnerType = [
  {
    value: "FREIGHT FORWARDER",
    label: "FREIGHT FORWARDER",
  },
  {
    value: "CARRIER",
    label: "CARRIER",
  },
  {
    value: "BROKER",
    label: "BROKER",
  }
]

export default function PartnerForm({
  readOnly,
  entityInUse,
  partner,
  partnerId,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  country,
  center,
  freightForwarder,
}: PartnerFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    control,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(dataScheme),
  });

  const [selectedCenter, setSelectedCenter] = useState<SelectedCenterType>({} as SelectedCenterType);
  const [editEmailMode, setEditEmailMode] = useState(false);
  const [editEmailModeCopy, setEditEmailModeCopy] = useState(false);
  const [editModeCenter, setEditModeCenter] = useState(false);

  const [consolidationCenter, setConsolidationCenter] = useState<ComboBoxType[]>([]);


  const findConsolidationCenterOnChange = async (id: number | string) => {

  }

  useEffect(() => {
    const countryId = watch('ID_PAIS');
    if (countryId) {
      findConsolidationCenter(countryId).then((consolidationResult) => {
        setConsolidationCenter(consolidationResult);
      });
    } else {
      setConsolidationCenter([]);
    }
  }, [watch('ID_PAIS')]);

  useEffect(() => {
    reset({});
    setEditEmailModeCopy(false);
    setEditEmailMode(false);
    setEditModeCenter(false);
  }, [partner, reset]);

  useEffect(() => {
    reset(partner);
    setEditEmailModeCopy(false);
    setEditEmailMode(false);
    if(partner && Object.keys(partner).length > 0) {
      const selectedCenterFounded = center.find((centerItem) => centerItem.id === partner?.partner_centers[0]?.ID_CENTRO)
      setSelectedCenter(selectedCenterFounded ?? {} as SelectedCenterType);
    }
  }, [partner, partnerId, reset, center]);
  
  const handleFormSubmit = (newPartner: any) => {
    onSubmit(newPartner);
  };

  const handleCancel = () => {
    reset({});
    onCancel();
  };
  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="partner-form"
    >
      {errors.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        data-testid="search"
        container
        marginTop={1}
        border={1}
        padding={2}
        spacing={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
      >

        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={6}>
            <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="Partner Name*"
                variant="outlined"
                size="small"
                {...register("NOME_PARCEIRO")}
                error={!!formErrors.NOME_PARCEIRO}
                inputProps={{ maxLength: 50 }}
                disabled={readOnly || entityInUse}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
                InputLabelProps={{ shrink: true }}
                data-testid="description-input"
                fullWidth
                label="MFIR*"
                variant="outlined"
                size="small"
                {...register("NUM_MFIR")}
                error={!!formErrors.NUM_MFIR}
                inputProps={{ maxLength: 10 }}
                disabled={readOnly || entityInUse}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
                name="ID_TIPO_PARCEIRO"
                // defaultValue={carrier.ID_MODAL_TRANSPORTE || undefined}
                control={control}
                render={({ field: { value, ref, onChange, onBlur, ...field } }) => (
                    <>
                      <Autocomplete
                          options={PartnerType}
                          getOptionLabel={(option) => option.label}
                          disabled={readOnly || entityInUse}
                          value={
                              PartnerType?.findLast(
                                  (item: any) => item?.value === value
                              ) || null
                          }
                          onBlur={onBlur}
                          renderInput={(params: any) => (
                              <TextField
                                  InputLabelProps={{ shrink: true }}
                                  {...params}
                                  {...field}
                                  label="Type*"
                                  size="small"
                                  inputRef={ref}
                                  error={!!formErrors.ID_TIPO_PARCEIRO}
                                  variant="outlined"
                                  disabled={readOnly || entityInUse}
                              />
                          )}
                          onChange={(_, data) => onChange(data?.value)}
                      />
                    </>
                )}

            />
          </Grid>
          <Grid item xs={3}>
            <Controller
                // defaultValue={carrier.ID_MODAL_TRANSPORTE || undefined}
                name="ID_PAIS"
                control={control}
                render={({ field: { value, ref, onChange, ...field } }) => (
                    <>
                      <Autocomplete
                          options={country}
                          getOptionLabel={(option) => option.description}
                          value={
                              country?.findLast(
                                  (item: any) => item?.id === value
                              ) || null
                          }
                          disabled={readOnly || entityInUse}
                          renderInput={(params: any) => (
                              <TextField
                                  InputLabelProps={{ shrink: true }}
                                  {...params}
                                  {...field}
                                  label="Country*"
                                  size="small"
                                  inputRef={ref}
                                  error={!!formErrors.ID_PAIS}
                                  variant="outlined"
                                  disabled={readOnly || entityInUse}
                              />
                          )}
                          onChange={(_: any, data: any) => {
                            findConsolidationCenterOnChange(data?.id);
                            onChange(data?.id || null);
                          }}
                      />
                    </>
                )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
                name="ID_CENTRO_CONSOLIDADOR"
                // defaultValue={carrier.ID_MODAL_TRANSPORTE || undefined}
                control={control}
                render={({ field: { value, ref, onChange, ...field } }) => (
                    <>
                      <Autocomplete
                          options={consolidationCenter}
                          getOptionLabel={(option) => option.description}
                          value={
                              consolidationCenter?.findLast(
                                  (item: any) => item?.id === value
                              ) || null
                          }
                          disabled={readOnly || entityInUse}
                          renderInput={(params: any) => (
                              <TextField
                                  {...params}
                                  {...field}
                                  label="Consolidation Center*"
                                  size="small"
                                  inputRef={ref}
                                  error={!!formErrors.ID_CENTRO_CONSOLIDADOR}
                                  variant="outlined"
                                  disabled={readOnly || entityInUse}
                              />
                          )}
                          onChange={(_: any, data: any) => {
                            onChange(data?.id || null);
                          }}
                      />
                    </>
                )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
                name="ID_AGENTE_CARGA"
                // defaultValue={carrier.ID_MODAL_TRANSPORTE || undefined}
                control={control}
                render={({ field: { value, ref, onChange, ...field } }) => (
                    <>
                      <Autocomplete
                          options={freightForwarder}
                          getOptionLabel={(option) => option.description}
                          value={
                              freightForwarder?.findLast(
                                  (item: any) => item?.id === value
                              ) || null
                          }
                          disabled={readOnly}
                          renderInput={(params: any) => (
                              <TextField
                                  {...params}
                                  {...field}
                                  label="Interface - Freigth Forwarder*"
                                  size="small"
                                  inputRef={ref}
                                  error={!!formErrors.ID_AGENTE_CARGA}
                                  variant="outlined"
                                  disabled={readOnly}
                              />
                          )}
                          onChange={(_: any, data: any) => {
                            onChange(data?.id || null);
                          }}
                      />
                    </>
                )}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              label="EDI Code*"
              variant="outlined"
              size="small"
              {...register("CODIGO_EDI", {
                onChange: (e) => {
                  e.target.value = e.target.value.replace(/\s/g, '');
                },
              })}
              error={!!formErrors.CODIGO_EDI}
              inputProps={{ maxLength: 6 }}
              disabled={readOnly || entityInUse}
              onKeyDown={(e) => {
                if (e.key === ' ') {
                  e.preventDefault();
                }
              }}
            />
          </Grid>
        </Grid>
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12}>
          <Divider sx={{ marginBottom: theme => theme.spacing(2) }} />
          <CenterForm
            readOnly={readOnly}
            entityInUse={entityInUse}
            setValue={setValue}
            watch={watch}
            register={register}
            control={control}
            formErrors={formErrors}
            center={center}
            selectedCenter={selectedCenter} 
            setSelectedCenter={setSelectedCenter}
            editEmailMode={editEmailMode}
            editEmailModeCopy={editEmailModeCopy}
            editModeCenter={editModeCenter}
            setEditModeCenter={setEditModeCenter}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ marginBottom: theme => theme.spacing(2) }} />
        </Grid>
        <Grid item xs={6}>
          <EmailForm
            readOnly={readOnly}
            setValue={setValue}
            watch={watch}
            register={register}
            control={control}
            formErrors={formErrors}
            selectedCenter={selectedCenter}
            editEmailMode={editEmailMode}
            setEditEmailMode={setEditEmailMode}
          />
        </Grid>
        <Grid item xs={6}>
          <EmailCopyForm
            readOnly={readOnly}
            setValue={setValue}
            watch={watch}
            register={register}
            control={control}
            formErrors={formErrors}
            selectedCenter={selectedCenter}
            editEmailMode={editEmailModeCopy}
            setEditEmailMode={setEditEmailModeCopy}
          />
        </Grid>
      </Grid>
        <Grid container justifyContent="flex-end" marginTop={2} spacing={2}>
          <Grid>
            <Button
              variant="outlined"
              onClick={handleCancel}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          {!readOnly && <Grid marginLeft={2}>
            <Button
              variant="contained"
              type="submit"
              data-testid="submit-button"
            >
              Confirm
            </Button>
          </Grid>}
        </Grid>
      </Grid>
    </form>
  );
}
