import { Autocomplete, Box, Button, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { ChangeEvent, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { ComboBoxType } from "../../../types/general";
import { BoardingProcessAttachmentType, Process } from "../../../types/process";
import ConfirmDialog, { ConfirmDialogType } from "../../../components/Dialog/ConfirmDialog";
import { Controller, FieldErrors } from "react-hook-form";
import { downloadFile, downloadFileFromUrl, viewFileInNewTabFromUrl, viewFileInNewTab } from "../../utils/file";
import InputUpload from "../../../components/Input/InputUpload";
import { getAttachmentFile } from "../../../services/process";

type AttachmentType = {
  FILE: File;
  TIPO_ANEXO: string;
  ID_PROCESSO_ANEXO?: number;
  DSC_PATH?: string;
  FILE_NAME?: string;
  ID_TIPO_ANEXO_PROCESSO?: string | number;
  NOME_ARQUIVO?: string;
  url?: string;
}

type ProcessFileAttachProps = {
  watch: (key: string) => {},
  setValue: (key: string, value: Partial<AttachmentType>[]) => void,
  control: any,
  processAttachmentTypes: BoardingProcessAttachmentType[],
  formErrors: FieldErrors<Process>,
  hasDisabledFields?: boolean;
}

const key = 'boardingProcessAttachments';

export default function ProcessFileAttach({
  watch,
  control,
  setValue,
  processAttachmentTypes,
  formErrors,
  hasDisabledFields
}: ProcessFileAttachProps) {
  const [selectedAttachmentType, setSelectedAttachmentType] = useState('');
  const [dialogMeta, setDialogMeta] = useState<ConfirmDialogType>({ open: false } as ConfirmDialogType);

  let attachments: (AttachmentType & { isNewFile?: boolean }) [] = watch(key) as Array<AttachmentType> || [];

  const addInput = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!selectedAttachmentType) {
      enqueueSnackbar("You must to select Attach type", { variant: "warning" });
      return;
    }

    if (event.target instanceof HTMLInputElement) {
      const files = event.target.files;
      if (files) {
        const fileToAdd = files[0];

        const existThisAttachType = attachments.find((attachment: AttachmentType) => attachment.TIPO_ANEXO === selectedAttachmentType);

        if (existThisAttachType) {
          setDialogMeta({
            open: true,
            message: `A file of type ${selectedAttachmentType} already exists. Do you want to replace it?`,
            callbackOnConfirm: () => updateFile(fileToAdd, existThisAttachType?.ID_PROCESSO_ANEXO!),
            callbackOnCancel: () => { setDialogMeta({ open: false }) }
          });

          return;
        }

        addFile(fileToAdd);
      }
    }
  }

  const updateFile = (fileToAdd: File, ID_PROCESSO_ANEXO: number) => {
    const filteredAttachment = attachments.filter((attachment: AttachmentType) => attachment.TIPO_ANEXO !== selectedAttachmentType);

    filteredAttachment.push({
      FILE: fileToAdd,
      TIPO_ANEXO: selectedAttachmentType,
      ID_PROCESSO_ANEXO
    });

    setValue(key, filteredAttachment);
    setDialogMeta({
      open: false
    })
  }

  const addFile = (fileToAdd: File) => {
    attachments.push({
      FILE: fileToAdd,
      TIPO_ANEXO: selectedAttachmentType,
      isNewFile: true
    });

    setValue(key, attachments);
  }

  const removeInput = (index: number, attachment: AttachmentType): void => {
    attachments.splice(index, 1);

    setValue(key, attachments);

    const { boardingProcessAttachmentsToDelete = [] } = control?._formValues || {};
    if (attachment.ID_PROCESSO_ANEXO) {
      setValue('boardingProcessAttachmentsToDelete', [
        ...boardingProcessAttachmentsToDelete, {
        ID_PROCESSO_ANEXO: attachment.ID_PROCESSO_ANEXO,
        TIPO_ANEXO: attachment.TIPO_ANEXO
      }]);
    }
  }

  const allowedProcessAttachmentTypes = hasDisabledFields ? processAttachmentTypes.filter(({ description }) => description === 'ENTRY FORM') : processAttachmentTypes;

  const getFileFromAttachment = async (attachment: AttachmentType) => {
    if (!attachment.ID_PROCESSO_ANEXO) return attachment.FILE;
    const data = await getAttachmentFile(attachment.ID_PROCESSO_ANEXO!);
    return new File([data], attachment.NOME_ARQUIVO!, { type: "application/pdf" });
  } 

  return (
    <Paper style={{ marginTop: 45, padding: 27 }}>
      <ConfirmDialog dialogMeta={dialogMeta} />
      <Box display="flex" flexDirection="row" gap={4}>
        <Controller
          name="TIPO_ANEXO"
          control={control}
          render={({ field: { value, ref, onChange, ...field } }) => (
            <>
              {
                allowedProcessAttachmentTypes && <Autocomplete
                  size="medium"
                  options={allowedProcessAttachmentTypes}
                  getOptionLabel={(option) => option.description}
                  value={value}
                  renderInput={(params) => (
                    <>
                      <TextField
                        style={{ width: "200px" }}
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="Attach type"
                        size="small"
                        variant="outlined"
                        error={!!formErrors.boardingProcessAttachments}
                      />
                    </>
                  )}
                  onChange={(_, data: ComboBoxType) => {
                    setSelectedAttachmentType(data?.description);
                    onChange(data?.description);
                  }}
                />
              }
            </>
          )}
        />
        <Box style={{ width: "40%", height: "100%" }} display="flex" flexDirection="row">
          <TextField
            size="small"
            fullWidth
            label="Find files..."
          />
          <InputUpload
            onInput={addInput}
            accept="application/pdf"
            onDrop={(files: FileList) => {
              addInput({ target: { files } } as ChangeEvent<HTMLInputElement>);
            }}
            customText="Upload"
            hideIcon
          />
        </Box>
      </Box>
      <Divider style={{ paddingTop: 15 }} />
      <Divider />
      <Box display="flex" marginTop={7} flexDirection="column" gap={1}>
        <Grid container marginTop={1}>
          <Grid item xs={6}>
            <Typography>
              <strong>
                Attachment Files Type
              </strong>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <span>
                File Name
              </span>
            </Typography>
          </Grid>
        </Grid>
        {attachments.map((attachment, index) => (
          <Grid container marginTop={1} key={index}>
            <Grid item xs={6}>
              <Typography>{attachment.ID_TIPO_ANEXO_PROCESSO ?? attachment.TIPO_ANEXO}</Typography>
            </Grid>
            <Grid display="flex" direction="row" item xs={6} justifyContent="space-between" gap={2}>
              <Typography>{attachment.ID_PROCESSO_ANEXO ? attachment.NOME_ARQUIVO : attachment.FILE.name}</Typography>
              <Box display="flex" flexDirection="row" gap={1}>
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: "primary",
                    maxHeight: "30px",
                    minWidth: "35px",
                    maxWidth: "35px",
                  }}
                  onClick={async () => {
                    const blobURL = attachment.url ?? URL.createObjectURL(await getFileFromAttachment(attachment));
                    if (!attachment.url) attachment.url = blobURL;
                    window.open(blobURL);
                  }}
                >
                  <RemoveRedEyeIcon />
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: "gray",
                    maxHeight: "30px",
                    minWidth: "35px",
                    maxWidth: "35px",
                  }}
                  onClick={async () => {
                    const blobURL = attachment.url ?? URL.createObjectURL(await getFileFromAttachment(attachment));
                    if (!attachment.url) attachment.url = blobURL;
                    const a = document.createElement('a');
                    a.href = blobURL;
                    a.download = attachment.NOME_ARQUIVO!;
                    a.click();
                  }}
                >
                  <DownloadIcon />
                </Button>
                <Button
                  fullWidth
                  onClick={() => removeInput(index, attachment)}
                  variant="contained"
                  style={{
                    backgroundColor: "red",
                    maxHeight: "30px",
                    minWidth: "35px",
                    maxWidth: "35px",
                  }}
                >
                  <DeleteIcon />
                </Button>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Paper>
  )
}